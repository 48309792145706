import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import FormField from '../../../components/FormField';
import isEqual from 'lodash.isequal';
import SystemMessage from '../../../components/SystemMessage';
import {
    convertStyledText,
    getCorrectDateFormat,
    getDateTimeFormat4Field,
    getSelectFieldValue,
    isClient,
    isJSON,
    isReviewer,
    userCanSeeComments,
    userCanSeeFinance,
} from '../../../utils/helpers';
import {
    checkForContinueSection,
    checkIfRefDataInCaseSessionStorage,
    isAssignSection,
    isAttachSection,
    isCaseCommentsSection,
    isFinanceSection,
    isInternalSection,
    isMedicalRecordsSection,
    isOwnerSection,
    isReadOnlyField,
    isReportSection,
    isSystemSection,
    onChangeCDD,
    onChangeRDD,
    onChangeTat,
    saveCaseSessionStorageData,
    saveToSessionStorageToCaseKey,
    getSessionStorageKey,
} from '../../../utils/case-helpers';
import { onListOptionsBuild, onRequiredBuild, runScripts, scriptParse } from '../../../utils/scripts-helpers';
import { editCase } from '../../../redux/actions/cases/caseListActions';
import {
    changeCaseOwner,
    getCase,
    getCaseFiles,
    getCaseValues,
    getPatientCaseLock,
    isAllValid,
    prepareCaseAttributes,
    prepareFields,
    removeCaseLocking,
    setCaseIsCreated,
    setCaseMessage,
    setNeedToReloadAssignments,
    setSubmitAfterValidation,
    showNotValidMessage,
    updateCaseValues,
} from '../../../redux/actions/cases/caseActions';
import { getCaseAssignment } from '../../../redux/actions/cases/caseAssignActions';
import { getCaseTemplate } from '../../../redux/actions/templateActions';
import { getActiveCountries, getActiveStates } from '../../../redux/actions/countryActions';
import draftToHtml from 'draftjs-to-html';
import ReactHtmlParser from 'react-html-parser';
import NavBar from './NavBar';
import AssignReviewers from './AssignReviewers';
import MedicalRecords from './../Attachments/MedicalRecords';
import Reports from '../Attachments/Reports';
import InternalCaseFiles from '../Attachments/InternalCaseFiles';
import SimpleReactValidator from 'utils/simple-react-validator';
import OwnersList from './OwnersList';
import { HashLink as Link } from 'react-router-hash-link';
import { getAll, getOne4Select } from '../../../redux/actions/BasicEntityActions';
import LoadingIndicator from '../../../components/LoadingIndicator';
import CaseCommentsForm from '../DefaultSections/CaseComments/CaseCommentsForm';
import FinanceSection from '../DefaultSections/Finance/FinanceSection';
import { Col, Row } from 'react-bootstrap';
import CollapseBox from 'components/CollapseBox/CollapseBox';
import { TAT_MAX, TAT_MIN } from '../../../utils/Constants';
import { getFieldValidators } from 'utils/field-validator';
//import { getCompanyProfile } from '../../../redux/actions/companiesActions'
let moment = require('moment');

class TemplatesInner extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            allLoaded: false,
            lang: 'EN',
            refAttempts: {},
            refListAttempts: {},
        };
        this.validator = new SimpleReactValidator();
        this.validator.showMessages();
        this.hiddenSections = [];
    }

    static propTypes = {
        caseid: PropTypes.string,
        fields: PropTypes.array,
        case: PropTypes.object,
        history: PropTypes.object,
        roleCode: PropTypes.string,
        sections: PropTypes.array,
        template: PropTypes.object,
        values: PropTypes.object,
        preparedAttributes: PropTypes.any,
        matrixCode: PropTypes.string,
        isEditable: PropTypes.bool,
        showNotValid: PropTypes.bool,
        user: PropTypes.object,
        caseMessageTime: PropTypes.string,
        acceptedUser: PropTypes.string,
        caseIsCreated: PropTypes.bool,
        caseMessage: PropTypes.string,
        caseMessageType: PropTypes.string,
        caseBackendMessage: PropTypes.string,
        error: PropTypes.bool,
        hasAnyCaseFieldUpdateError: PropTypes.bool,

        ref_languages: PropTypes.array,
        reports: PropTypes.array,
        my_permissions: PropTypes.array,
        reviewer_types: PropTypes.array,
        case_types: PropTypes.array,
        users: PropTypes.array,
        assignmentDetails: PropTypes.array,

        getAll: PropTypes.func,
        getCase: PropTypes.func,
        getCaseFiles: PropTypes.func,
        getCaseTemplate: PropTypes.func,
        getCaseAssignment: PropTypes.func,
        getCaseValues: PropTypes.func,
        getPatientCaseLock: PropTypes.func,
        getActiveCountries: PropTypes.func,
        getActiveStates: PropTypes.func,
        getOne4Select: PropTypes.func,
        isAllValid: PropTypes.func,
        updateCase: PropTypes.func,
        prepareCaseAttributes: PropTypes.func,
        setSubmitAfterValidation: PropTypes.func,
        setCaseMessage: PropTypes.func,
        setCaseIsCreated: PropTypes.func,
        setNeedToReloadAssignments: PropTypes.func,
        showNotValidMessage: PropTypes.func,
        changeCaseOwner: PropTypes.func,
        updateCaseValues: PropTypes.func,
        prepareFields: PropTypes.func,
    };

    componentDidMount() {
        this.props.showNotValidMessage(false);
        window.addEventListener('beforeunload', this.removeCurrentCaseFromSessionStorage);

        //this.createWebsocketConnection();
        this.props.getPatientCaseLock(this.props['caseid']).then(() => {
          this.props.getCase(this.props['caseid']).then(() => {
            if (isReviewer(this.props.roleCode) && this.props.case.currentStage.code.indexOf('REVIEW') === -1) {
                this.props.history.push('/');
            }
            //this.props.getCompanyProfile(this.props.case.company);
            this.props.getCaseFiles(this.props['caseid']);
            this.props.getCaseTemplate(this.props['caseid']).then(() => {
                if (this.props.case.currentStage.code.indexOf('REVIEW') !== -1 || this.props.case.currentStage.code.indexOf('DISPATCH') !== -1) {
                    this.props.getCaseAssignment(this.props['caseid']);
                }
                this.props.getCaseValues(this.props['caseid']).then(() => {
                    this.setState({ allLoaded: true }, () => {
                        setTimeout(() => {
                            this.dispatchValidState();
                        }, 500);
                    });
                });
            });
        });
        });
        this.hardFields = [];
        this.props.setCaseMessage({
            text: '',
        });
        let countries = localStorage.getItem('list_country');
        if (!countries) {
            this.props.getActiveCountries();
        }
        let states = localStorage.getItem('list_state');
        if (!states) {
            setTimeout(this.props.getActiveStates, 1000);
        }
    }

    // TODO
    componentWillUnmount() {
      if (!window.location.href.includes(this.props.case.code)) {
        this.removeCurrentCaseFromSessionStorage();
      }
      window.removeEventListener('beforeunload', this.removeCurrentCaseFromSessionStorage);
      /*if (this.socket) {
        this.socket.close();
      }*/
    }
  
    
    removeCurrentCaseFromSessionStorage = () => {
      const tabTimeStamp = sessionStorage.getItem(getSessionStorageKey(this.props.case.code) + '_tabCreatedAt');
      this.props.removeLock(this.props.case.code, tabTimeStamp);
    };

    /*createWebsocketConnection = () => {
      const protocol = window.location.protocol === 'https:' ? 'wss://' : 'ws://';
      const host = 8084;
      const socketUrl = `${protocol}${window.location.hostname}:${host}/websocket`;

      console.log(`WebSocket connecting to: ${socketUrl}`);
      this.socket = new WebSocket(socketUrl);

      this.socket.onopen = () => {
        console.log('WebSocket connection established');
      };

      this.socket.onmessage = (event) => {
        console.log('Received message:', event.data);
        if (event.data !== this.props.case.code + sessionStorage.getItem('tabCreatedAt')) {
          this.props.getCaseValues(this.props['caseid']);
        }
      };

      this.socket.onclose = (event) => {
        console.log('WebSocket connection closed');
        console.log('Close event:', event);
        if (event.wasClean) {
            console.log(`Connection closed cleanly, code=${event.code}, reason=${event.reason}`);
        } else {
            console.error('Connection died unexpectedly');
        }
      };

      this.socket.onerror = (error) => {
        console.error('WebSocket error observed:', error);
      };
    }*/
  


    // TODO: deprecated react method
    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps(nextProps) {
        //VALIDATE CASE AGAIN IF Assignment STATUS HAS BEEN changed
        //(WHEN REVIEWER ACCEPT THE CASE AND FIELDS BECOME EDITABLE)
        if (JSON.stringify(this.props.case) !== JSON.stringify(nextProps.case)) {
            this.props.isAllValid(this.getInnerValidationState());
        }

        // CLEAR VALIDATOR WHEN CASE CHANGES STAGE
        if (this.props.case.currentStage.name !== nextProps.case.currentStage.name) {
            Object.keys(this.validator.errorMessages).forEach((field) => (this.validator.errorMessages[field] = null));
            Object.keys(this.validator.fields).forEach((field) => (this.validator.fields[field] = true));
        }

        if (nextProps.sections && this.state.firstLoad && JSON.stringify(nextProps.sections) !== JSON.stringify(this.props.sections)) {
            this.setState({
                sections: nextProps.sections,
            });
            const data = {};
            for (let i = 0; i < nextProps.sections.length; i += 1) {
                let section = nextProps.sections[i];
                for (let j = 0; j < this.props.fields.length; j += 1) {
                    let field = this.props.fields[j];
                    if (section['fieldInstances'].includes(field.code)) {
                        data[field.code] = field.defaultValue;
                    }
                }
            }
            this.setState({ firstLoad: false, data: data });
        }
    }

    getInnerValidationState = () => {
        const { fields } = this.validator;

        let valid = true;
        for (let key in fields) {
            if (!fields.hasOwnProperty(key)) continue;
            if (this.hiddenFields.includes(key)) continue;
            if (this.notRequiredFields.includes(key)) continue;
            valid = valid && fields[key];
        }
        if (this.hardFields.length > 0) {
            valid = false;
        }
        //console.log(this.hardFields)
        return valid;
    };

    // Do or do not. There is no try. - Yoda
    onTryAutoSaveField = () => {
        if (
            (!Array.isArray(this.props.preparedAttributes) || typeof this.props.preparedAttributes[0].data === 'undefined') &&
            typeof this.props.preparedAttributes.data === 'undefined'
        ) {
            this.updateValues();
        } else {
            this.updateCaseAttributes();
            this.updateValues();
        }
        if (this.props.isEditable && !this.props.isLocked && sessionStorage.getItem(getSessionStorageKey(this.props.case.code) + '_tabCreatedAt')) {
            this.setStageOwner();
        }
    };

    dispatchValidState = () => {
        this.props.isAllValid(this.getInnerValidationState());
        this.props.setSubmitAfterValidation();

        if (this.props.showNotValid) {
            if (!this.getInnerValidationState()) {
                // this.validator.showMessages();
                this.forceUpdate();
            } else {
                this.props.showNotValidMessage(false);
            }
            this.onTryAutoSaveField();
            return false;
        } else {
            this.onTryAutoSaveField();
            return true;
        }
    };

    setStageOwner = () => {
        if (this.props.case.currentStage.code.indexOf('CLOSED') === -1) {
            const { user, changeCaseOwner } = this.props;
            const stage = this.props.case.currentStage.name === 'QA' ? 'Qa' : this.props.case.currentStage.name;
            const stageName = 'owner' + stage;
            if (!this.props.case[stageName] && stageName !== 'CW_REVIEW' && user.roleCode !== 'ROLE_REVIEWER') {
                changeCaseOwner(stageName, user);
            }
        }
    };

    updateValues = () => {
        this.props.updateCaseValues(this.props.case.code);
    };

    updateCaseAttributes = (attributes) => {
        if (this.props.case.currentStage.code.indexOf('CLOSED') === -1) {
            let obj = {
                ...this.props.case,
                patientCaseType: this.props.case.patientCaseType,
                level: this.props.case.level,
                clientDueDate: this.props.case.clientDueDate,
                reviewerDueDate: this.props.case.reviewerDueDate,
                determinationCode: this.props.case.determinationCode,
                serviceType: this.props.case.serviceType,
            };
            if (typeof attributes === 'undefined') {
                attributes = !Array.isArray(this.props.preparedAttributes) ? [this.props.preparedAttributes] : this.props.preparedAttributes;
            }
            let countChanges = 0;
            for (let i = 0; i < attributes.length; i += 1) {
                let fieldCode = attributes[i].code;
                let fieldData = attributes[i].data;
                if (fieldCode.replace(/\s+/g, '') === 'caseNumber_' + this.props.template.code) {
                    if (obj.number !== fieldData) {
                        obj.number = fieldData;
                        countChanges += 1;
                    }
                }
                if (fieldCode.replace(/\s+/g, '') === 'caseType_' + this.props.template.code) {
                    if (obj.patientCaseType !== fieldData) {
                        obj.patientCaseType = fieldData;
                        countChanges += 1;
                    }
                }
                if (fieldCode.replace(/\s+/g, '') === 'caseLevel_' + this.props.template.code) {
                    if (obj.level !== fieldData) {
                        obj.level = fieldData;
                        countChanges += 1;
                    }
                }
                if (fieldCode.replace(/\s+/g, '') === 'dueDate_' + this.props.template.code) {
                    let dueDate = new Date(fieldData);
                    let timezoneOffsetInMilliSec = dueDate.getTimezoneOffset() * 60 * 1000;
                    //Backend expects the milliSec in user timezone, not UTC
                    let dueDateMilliSec = Date.parse(fieldData) - timezoneOffsetInMilliSec;
                    if (obj.clientDueDate !== dueDateMilliSec) {
                        obj.clientDueDate = dueDateMilliSec;
                        countChanges += 1;
                    }
                }
                if (fieldCode.replace(/\s+/g, '') === 'reviewerDueDate_' + this.props.template.code) {
                    let reviewerDueDate = new Date(fieldData);
                    let timezoneOffsetInMilliSec = reviewerDueDate.getTimezoneOffset() * 60 * 1000;
                    //Backend expects the milliSec in user timezone, not UTC
                    let reviewerDueDateMilliSec = Date.parse(fieldData) - timezoneOffsetInMilliSec;
                    if (obj.reviewerDueDate !== reviewerDueDateMilliSec) {
                        obj.reviewerDueDate = reviewerDueDateMilliSec;
                        countChanges += 1;
                    }
                }
                if (fieldCode.replace(/\s+/g, '') === 'serviceType_' + this.props.template.code) {
                    if (obj.serviceType !== fieldData) {
                        obj.serviceType = fieldData;
                        countChanges += 1;
                    }
                }
                if (fieldCode.replace(/\s+/g, '') === 'determination_' + this.props.template.code) {
                    if (obj.determinationCode !== fieldData) {
                        obj.determinationCode = fieldData;
                        countChanges += 1;
                    }
                }
            }
            if (countChanges > 0 && !this.props.isLocked) {
                saveCaseSessionStorageData(this.props.case.code, 'attributes', {});
                this.props.updateCase(obj).then(() => {
                    this.props.getCaseAssignment(this.props.case.code);
                    this.props.getCase(this.props.case.code).then(() => {
                        this.props.prepareCaseAttributes([{}]);
                        this.setStageOwner();
                    });
                });
            }
        }
    };

    // shouldComponentUpdate(nextProps, nextState) {
    //   return JSON.stringify(nextProps) !== JSON.stringify(this.props) || JSON.stringify(nextState) !== JSON.stringify(this.state)
    // }

    isValidTATKey = (key) => {
        return [
            '0',
            '1',
            '2',
            '3',
            '4',
            '5',
            '6',
            '7',
            '8',
            '9',
            'Backspace',
            'Delete',
            'Home',
            'End',
            'ArrowLeft',
            'ArrowRight',
            'ArrowUp',
            'ArrowDown',
        ].includes(key);
    };

    render() {
        if (!this.state.allLoaded) {
            return <LoadingIndicator height={50} />;
        } else {
            //console.group('render');

            let sectionLinks = [];
            let sectionsBody = [];
            this.notRequiredFields = [];
            let specValidationMessages = { softValidation: [], hardValidation: [] };
            saveCaseSessionStorageData(this.props.case.code, 'values', this.props.values.fieldData);

            if (this.props.sections) {
                const dataValues = this.props.values.fieldData;
                let data4prepare = [];
                let reports_length = this.props.reports.length;

                for (let i = 0; i < this.props.sections.length; i += 1) {
                    let section = this.props.sections[i];
                    let sectionName = section.displayName[this.state.lang];

                    if (checkForContinueSection(section, this.props.fields, this.props.matrixCode, reports_length, this.props.my_permissions)) {
                        // console.log('CHECK SECTION', { section: section, matrixCode: this.props.matrixCode, permissions: this.props.my_permissions });
                        continue;
                    }

                    if (!this.hiddenSections.includes(sectionName)) {
                        sectionLinks.push(
                            <li className="additional-nav__item" key={section.code} id={sectionName + 'LINK'}>
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <Link
                                    smooth
                                    onClick={(e) => e.preventDefault()}
                                    className="additional-nav__link"
                                    scroll={(el) => window.scrollTo({ top: el.offsetTop, behavior: 'smooth' })}
                                    to={'/case/' + this.props['caseid'] + '#' + sectionName}
                                >
                                    {sectionName}
                                </Link>
                            </li>,
                        );
                    } else {
                        sectionLinks.push(
                            <li className="additional-nav__item" key={section.code} id={sectionName + 'LINK'} style={{ display: 'none' }}>
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <Link
                                    smooth
                                    onClick={(e) => e.preventDefault()}
                                    className="additional-nav__link"
                                    scroll={(el) => window.scrollTo({ top: el.offsetTop, behavior: 'smooth' })}
                                    to={'/case/' + this.props['caseid'] + '#' + sectionName}
                                >
                                    {sectionName}
                                </Link>
                            </li>,
                        );
                    }

                    let fieldsBody = [];
                    if (!isSystemSection(section)) {
                        if (this.props.fields) {
                            for (let j = 0; j < this.props.fields.length; j += 1) {
                                let field = this.props.fields[j];
                                if (!field.visibilityMatrix[this.props.matrixCode]) continue;
                                if (section['fieldInstances'] && section['fieldInstances'].includes(field.code)) {
                                    let md = 4;
                                    let xs = 12;
                                    let newRow = field.customClasses.includes('NewRow') ? <div className="clearfix" /> : '';
                                    let endOfRow = field.customClasses.includes('EndOfRow') ? <div className="clearfix" /> : '';

                                    if (field.customClasses.indexOf('XWideField') !== -1) {
                                        md = 8;
                                    }
                                    if (field.customClasses.indexOf('XXWideField') !== -1) {
                                        md = 12;
                                    }
                                    if (field.customClasses.indexOf('SWideField') !== -1) {
                                        md = 2;
                                        xs = 6;
                                    }
                                    let rows = 5;
                                    if (field.customClasses.indexOf('XTallMemoField') !== -1) {
                                        rows = 8;
                                    }
                                    if (field.customClasses.indexOf('XXTallMemoField') !== -1) {
                                        rows = 12;
                                    }
                                    let fieldType = field.fieldType;
                                    if (fieldType === 'TEXT' || fieldType === 'CASE_QUESTION') {
                                        fieldType = 'TEXT_READ';
                                    }
                                    if (fieldType === 'LABEL') {
                                        fieldType = 'label_field';
                                    }
                                    if (fieldType === 'BOOLEAN') {
                                        xs = xs / 2;
                                        fieldType = 'checkbox';
                                    }

                                    // LABEL && VALUE
                                    let fieldValue;
                                    if (this.props.values.fieldData) {
                                        const templateField = this.props.fields.find((item) => item.code === field.code);
                                        const fieldDefaultValue = templateField ? templateField.defaultValue : '';
                                        fieldValue =
                                            this.props.values.fieldData[field.code] === null ||
                                            typeof this.props.values.fieldData[field.code] === 'undefined'
                                                ? fieldDefaultValue
                                                : this.props.values.fieldData[field.code];
                                    } else {
                                        fieldValue = '';
                                    }

                                    let names = field.names != null ? field.names[this.state.lang] : ' ';
                                    if (fieldType === 'label_field') {
                                        names = fieldValue ? fieldValue : field['defaultValueName'];
                                    }

                                    let fieldLabel = names;

                                    if (isJSON(names)) {
                                        let htmlString = draftToHtml(JSON.parse(names));
                                        htmlString = htmlString.replace('<p>', '<span>');
                                        htmlString = htmlString.replace('</p>', '</span>');
                                        fieldLabel = ReactHtmlParser(htmlString);
                                    }

                                    let fieldText = isJSON(names) ? JSON.parse(names).blocks[0].text : names;
                                    if (fieldType === 'label_field') {
                                        fieldValue = '';
                                    }
                                    fieldValue = isJSON(fieldValue) ? JSON.parse(fieldValue) : fieldValue;
                                    if (fieldType === 'checkbox') fieldValue = fieldValue === 'true' || fieldValue === true;
                                    if (['DATE', 'TIME', 'DATE_TIME'].indexOf(fieldType) !== -1) {
                                        fieldValue = getDateTimeFormat4Field(fieldType, fieldValue);
                                    }
                                    let readonly = isReadOnlyField(
                                        field,
                                        this.props.user,
                                        this.props.case,
                                        this.props.matrixCode,
                                        this.props.assignmentDetails,
                                        this.props.isFinancesLoading,
                                        this.props.isLocked
                                    );
                                    //READONLY RULES
                                    if (field.readOnlyMatrixScript !== '' && field.readOnlyMatrixScript !== null) {
                                        let readOnlyBuilt = onRequiredBuild(
                                            field.readOnlyMatrixScript,
                                            this.props.template.code,
                                            this.props.values.fieldData,
                                        );
                                        let readOnlyScript;
                                        try {
                                            // eslint-disable-next-line no-eval
                                            readOnlyScript = eval(readOnlyBuilt);
                                        } catch (e) {
                                            console.error('ERROR ' + e.name + ':' + e.message + '\n' + e.stack);
                                            console.warn(readOnlyBuilt);
                                        }
                                        //console.log(field.name, '***', readOnlyScript ,"***", readonly)
                                        readonly = readOnlyScript && readonly;
                                    }

                                    // LIST OPTIONS
                                    let fieldOptions = [];
                                    let sorted = false;
                                    let tempFieldType = fieldType;
                                    if (
                                        field.listOptions &&
                                        field.listOptions !== [] &&
                                        field.listOptions !== '[]' &&
                                        field.listOptions !== '' &&
                                        field.listOptions !== 'null'
                                    ) {
                                        if (isJSON(field.listOptions)) {
                                            fieldOptions = JSON.parse(field.listOptions);
                                        } else {
                                            fieldOptions = field.listOptions.split(',').map((item) => ({
                                                value: item,
                                                label: item,
                                            }));
                                        }
                                        // if (fieldType.indexOf('REF') === 0) {
                                        //     let refOptions = JSON.parse(localStorage.getItem('list_' + fieldType.substr(4).toLowerCase())) || {};
                                        //     if (refOptions) {
                                        //         let refArray = [];
                                        //         for (let refKey in refOptions) {
                                        //             refArray.push({
                                        //                 value: refKey,
                                        //                 label: refOptions[refKey]
                                        //             })
                                        //         }
                                        //         fieldOptions = refArray.concat(fieldOptions);
                                        //     }
                                        // }

                                        if (fieldValue !== '' && fieldValue !== [] && fieldValue !== '[]' && fieldValue !== undefined) {
                                            let found = false;
                                            if (field.allowMultiSelect && Array.isArray(fieldValue)) {
                                                for (let j = 0; j < fieldValue.length; j += 1) {
                                                    if (fieldValue === '' || fieldValue === [] || fieldValue === '[]') continue;
                                                    found = false;
                                                    for (let i = 0; i < fieldOptions.length; i += 1) {
                                                        if (fieldOptions[i].value === fieldValue[j]) {
                                                            found = true;
                                                            break;
                                                        }
                                                    }
                                                    if (!found) {
                                                        fieldOptions.push({ value: fieldValue[j], label: fieldValue[j] });
                                                    }
                                                }
                                            } else {
                                                found = false;
                                                for (let i = 0; i < fieldOptions.length; i += 1) {
                                                    if (fieldOptions[i].value === fieldValue) {
                                                        found = true;
                                                        break;
                                                    }
                                                }
                                                if (!found) {
                                                    const caseTypeValue = this.props.case_types.find((item) => item.code === fieldValue);
                                                    if (fieldType === 'REF_CASE_TYPE' && caseTypeValue !== undefined) {
                                                        fieldOptions.push({ value: caseTypeValue.code, label: caseTypeValue.name });
                                                    } else {
                                                        fieldOptions.push({ value: fieldValue, label: fieldValue });
                                                    }
                                                    //fieldOptions.push({ value: fieldValue, label: fieldValue });
                                                }
                                            }
                                        }
                                        fieldType = 'select';
                                        sorted = true;
                                    }
                                    // LIST OPTIONS SCRIPT
                                    if (field.listOptionsScript !== '' && field.listOptionsScript != null && !readonly) {
                                        let loBuilt = onListOptionsBuild(
                                            field.listOptionsScript,
                                            this.props.template.code,
                                            this.props.values.fieldData,
                                            this.props.fields,
                                        );
                                        let refArrayName = loBuilt.type.toLocaleLowerCase() + 's';
                                        if (this.props[refArrayName]) {
                                            fieldOptions = [];
                                            let newListOptionsEl = this.props[refArrayName].find((item) => item.code === loBuilt.code);
                                            if (typeof newListOptionsEl !== 'undefined') {
                                                let newListOptions = newListOptionsEl[loBuilt.list];
                                                if (!newListOptions) newListOptions = newListOptionsEl[loBuilt.list.replace('Codes', 's')];
                                                if (newListOptions != null) {
                                                    if (typeof newListOptions === 'string') newListOptions = newListOptions.split(',');
                                                    let newListOptions_temp = [];
                                                    let refArray_temp = this.props[tempFieldType.toLocaleLowerCase() + 's'];
                                                    for (let j = 0; j < newListOptions.length; j += 1) {
                                                        let loItem = newListOptions[j].replace(/^\s*(.*)\s*$/, '$1');
                                                        let item = refArray_temp.find((item) => item.name === loItem || item.code === loItem);
                                                        if (item) newListOptions_temp.push({ value: item.code, label: item.name });
                                                    }
                                                    fieldOptions = newListOptions_temp;
                                                    if (fieldOptions.length === newListOptions.length) fieldType = 'select';
                                                }

                                                if (typeof newListOptions === 'undefined') {
                                                    refArrayName = 'ref_' + loBuilt.list;
                                                    if (this.props[refArrayName]) {
                                                        if (typeof this.state.refListAttempts[refArrayName] === 'undefined') {
                                                            let refListAttempts = this.state.refListAttempts;
                                                            refListAttempts[refArrayName] = 1;
                                                            this.setState({ refListAttempts: refListAttempts });
                                                            this.props.getAll(loBuilt.list);
                                                        }
                                                        let codesName = loBuilt['type'].substr(4).toLowerCase() + 'Codes';
                                                        let items = this.props[refArrayName].filter(
                                                            (item) => item[codesName] && item[codesName].includes(loBuilt.code),
                                                        );
                                                        if (items)
                                                            fieldOptions = items.map((item) => ({
                                                                value: item.code,
                                                                label: item.name,
                                                            }));
                                                    }
                                                }
                                                fieldType = 'select';
                                            }
                                        }
                                    }
                                    // HACK FOR FACILITY PHYSICIAN AND PATIENT
                                    if (fieldType === 'REF_PHYSICIAN') fieldType = 'REF_PHYSICIAN_COMPANY';

                                    // CLEARABLE
                                    let clearable = true;

                                    // FIELD MAPPING TO CASE
                                    let isPreparedCaseData = !(
                                        (!Array.isArray(this.props.preparedAttributes) ||
                                            typeof this.props.preparedAttributes[0].data === 'undefined') &&
                                        typeof this.props.preparedAttributes.data === 'undefined'
                                    );
                                    let clearPreparedCode = isPreparedCaseData ? this.props.preparedAttributes[0].code.replace(/\s+/g, '') : '';
                                    let clearCode = field.code.replace(/\s+/g, '');
                                    if (
                                        (!isPreparedCaseData || clearPreparedCode !== 'caseCode_' + this.props.template.code) &&
                                        clearCode === 'caseCode_' + this.props.template.code
                                    ) {
                                        fieldValue = this.props.case.caseId;
                                    }

                                    if (
                                        (!isPreparedCaseData || clearPreparedCode !== 'resubmitReason_' + this.props.template.code) &&
                                        clearCode === 'resubmitReason_' + this.props.template.code
                                    ) {
                                        const reasonField = this.props.fields.find((item) => item.code.includes(clearCode));
                                        if (typeof reasonField !== 'undefined') {
                                            const listOptions = JSON.parse(reasonField.listOptions);
                                            const reason = listOptions.find((option) => option['value'] === fieldValue);
                                            if(reason) {
                                                fieldValue = reason['label'];
                                            }
                                        }
                                    }

                                    if (
                                        (!isPreparedCaseData || clearPreparedCode !== 'caseNumber_' + this.props.template.code) &&
                                        clearCode === 'caseNumber_' + this.props.template.code
                                    ) {
                                        fieldValue = this.props.case.number;
                                    }

                                    if (
                                        (!isPreparedCaseData || clearPreparedCode !== 'caseType_' + this.props.template.code) &&
                                        clearCode === 'caseType_' + this.props.template.code
                                    ) {
                                        //[MED-604] Quickfix: IF "fieldValue" is an object, it will break the view
                                        // let foundCT = this.props.ref_case_types.find((item) => item.code === this.props.case.patientCaseType);
                                        // if (typeof foundCT !== 'undefined') {
                                        //     fieldValue = {
                                        //         value: this.props.case.patientCaseType,
                                        //         label: foundCT['names'][this.state.lang],
                                        //     };
                                        // } else {
                                        //     fieldValue = this.props.case.patientCaseType;
                                        // }
                                        fieldValue = this.props.case.patientCaseType;
                                        const data = JSON.parse(sessionStorage.getItem(getSessionStorageKey(this.props.case.code)));
                                        saveCaseSessionStorageData(this.props.case.code, 'values', {
                                            ...data.values,
                                            [field.code]: fieldValue,
                                        });
                                    }

                                    if (
                                        (!isPreparedCaseData || clearPreparedCode !== 'caseLevel_' + this.props.template.code) &&
                                        clearCode === 'caseLevel_' + this.props.template.code
                                    ) {
                                        //[MED-604] Quickfix: IF "fieldValue" is an object, it will break the view
                                        // let foundCL = this.props.ref_case_levels.find((item) => item.code === this.props.case.level);
                                        // if (typeof foundCL !== 'undefined') {
                                        //     fieldValue = {
                                        //         value: this.props.case.level,
                                        //         label: foundCL['displayName'][this.state.lang],
                                        //     };
                                        // } else {
                                        //     fieldValue = this.props.case.level;
                                        // }
                                        fieldValue = this.props.case.level;
                                    }

                                    if (
                                        (!isPreparedCaseData || clearPreparedCode !== 'caseLanguage_' + this.props.template.code) &&
                                        clearCode === 'caseLanguage_' + this.props.template.code &&
                                        fieldType === 'REF_LANGUAGE' &&
                                        readonly
                                    ) {
                                        //[MED-604] Quickfix: IF "fieldValue" is an object, it will break the view
                                        let foundLanguage = this.props.ref_languages.find((item) => item.code === this.props.case.language);
                                        if (typeof foundLanguage !== 'undefined') {
                                            fieldValue = foundLanguage['name'];
                                        } else {
                                            fieldValue = this.props.case.language;
                                        }
                                    }

                                    if (
                                        (!isPreparedCaseData || clearPreparedCode !== 'serviceType_' + this.props.template.code) &&
                                        clearCode === 'serviceType_' + this.props.template.code &&
                                        this.props.case.serviceType
                                    ) {
                                        //[MED-604] Quickfix: IF "fieldValue" is an object, it will break the view
                                        // let foundST = this.props.ref_service_types.find((item) => item.code === this.props.case.serviceType);
                                        // if (typeof foundST !== 'undefined') {
                                        //     fieldValue = {
                                        //         value: this.props.case.serviceType,
                                        //         label: foundST['name'],
                                        //     };
                                        // } else {
                                        //     fieldValue = this.props.case.serviceType;
                                        // }
                                        fieldValue = this.props.case.serviceType;
                                    }
                                    if (
                                        (!isPreparedCaseData || clearPreparedCode !== 'dueDate_' + this.props.template.code) &&
                                        clearCode === 'dueDate_' + this.props.template.code
                                    ) {
                                        fieldValue = this.props.case.clientDueDate;
                                        if (this.props.caseIsCreated) {
                                            if (field.defaultValueScript.indexOf('setDefaultTime(') !== -1) {
                                                let defTime = field.defaultValueScript.replace('setDefaultTime(', '');
                                                defTime = defTime.split(/(["'])(.+?)\1/);
                                                if (typeof defTime[2] !== 'undefined') {
                                                    let tempDate = new Date(fieldValue);
                                                    tempDate = moment(tempDate).format('L') + ' ' + defTime[2];
                                                    dataValues['dueDate_' + this.props.template.code] = tempDate;
                                                    data4prepare.push({
                                                        data: tempDate || '',
                                                        code: 'dueDate_' + this.props.template.code,
                                                    });
                                                    saveCaseSessionStorageData(this.props.case.code, 'values', dataValues);
                                                }
                                            }
                                        }
                                    }
                                    if (
                                        (!isPreparedCaseData || clearPreparedCode !== 'reviewerDueDate_' + this.props.template.code) &&
                                        clearCode === 'reviewerDueDate_' + this.props.template.code
                                    ) {
                                        fieldValue = this.props.case.reviewerDueDate;
                                        if (this.props.caseIsCreated) {
                                            if (field.defaultValueScript.indexOf('setDefaultTime(') !== -1) {
                                                let defTime = field.defaultValueScript.replace('setDefaultTime(', '');
                                                defTime = defTime.split(/(["'])(.+?)\1/);
                                                if (typeof defTime[2] !== 'undefined') {
                                                    let tempDate = new Date(fieldValue);
                                                    tempDate = moment(tempDate).format('L') + ' ' + defTime[2];
                                                    dataValues['reviewerDueDate_' + this.props.template.code] = tempDate;
                                                    data4prepare.push({
                                                        data: tempDate || '',
                                                        code: 'reviewerDueDate_' + this.props.template.code,
                                                    });
                                                    saveCaseSessionStorageData(this.props.case.code, 'values', dataValues);
                                                }
                                            }
                                        }
                                    }
                                    if (
                                        (!isPreparedCaseData || clearPreparedCode !== 'determination_' + this.props.template.code) &&
                                        clearCode === 'determination_' + this.props.template.code
                                    ) {
                                        fieldValue = this.props.case.determinationCode || '';
                                    }
                                    if (clearCode === 'turnAroundTime_' + this.props.template.code) {
                                        if (!fieldValue && this.props.caseIsCreated) {
                                            let caseType = this.props.case_types.find((item) => item.code === this.props.case.patientCaseType);
                                            if (caseType) {
                                                fieldValue = caseType.tat ? caseType.tat : '';
                                                //const data = this.props.values.fieldData;
                                                dataValues['turnAroundTime_' + this.props.template.code] = parseInt(fieldValue, 10);
                                                saveCaseSessionStorageData(this.props.case.code, 'values', dataValues);
                                                //this.props.setCaseIsCreated(false);
                                            }
                                        }
                                    }
                                    if (clearCode === 'pageCount_' + this.props.template.code) {
                                        fieldValue =
                                            typeof this.props.case['billablePagesCount'] !== 'undefined' ? this.props.case['billablePagesCount'] : 0;
                                    }
                                    if (clearCode === 'reviewerType_' + this.props.template.code) {
                                        let accepted = '';
                                        let users = this.props.users;
                                        let usLength = users.length;
                                        for (let i = 0; i <= usLength; i += 1) {
                                            if (typeof users[i] !== 'undefined' && users[i].code === this.props.acceptedUser) {
                                                accepted = users[i];
                                            }
                                        }
                                        if (accepted === '') {
                                            if (typeof this.state.refAttempts[fieldType + '_' + this.props.acceptedUser] === 'undefined') {
                                                this.props.getOne4Select(this.props.acceptedUser, 'user');
                                                let refAttempts = this.state.refAttempts;
                                                refAttempts[fieldType + '_' + this.props.acceptedUser] = 1;
                                                this.setState({ refAttempts: refAttempts });
                                            }
                                        } else {
                                            let reviewerTypeCodes = '';
                                            if (
                                                accepted !== '' &&
                                                typeof accepted['reviewerTypeCodes'] !== 'undefined' &&
                                                accepted['reviewerTypeCodes'] != null
                                            ) {
                                                reviewerTypeCodes = accepted['reviewerTypeCodes'];
                                            }
                                            let reviewerCodes = [];
                                            for (let i = 0; i < reviewerTypeCodes.length; i += 1) {
                                                reviewerCodes.push(this.props.reviewer_types[reviewerTypeCodes[i]]);
                                            }
                                            fieldValue = reviewerCodes;
                                        }
                                    }

                                    // HELP TEXT
                                    let helpText = '';
                                    if (isClient(this.props.roleCode)) {
                                        if (field.helpTextClient)
                                            helpText = isJSON(field.helpTextClient[this.state.lang])
                                                ? draftToHtml(JSON.parse(field.helpTextClient[this.state.lang]))
                                                : field.helpTextClient[this.state.lang];
                                    } else if (isReviewer(this.props.roleCode)) {
                                        if (field.helpTextReviewer)
                                            helpText = isJSON(field.helpTextReviewer[this.state.lang])
                                                ? draftToHtml(JSON.parse(field.helpTextReviewer[this.state.lang]))
                                                : field.helpTextReviewer[this.state.lang];
                                    } else {
                                        if (field.helpTextStaff)
                                            helpText = isJSON(field.helpTextStaff[this.state.lang])
                                                ? draftToHtml(JSON.parse(field.helpTextStaff[this.state.lang]))
                                                : field.helpTextStaff[this.state.lang];
                                    }
                                    // if (field.code === 'testAllOptions_95100541051136610111973') {
                                    //     console.log(fieldType);
                                    // }

                                    // READONLY VIEW
                                    let creatableOptions = [];

                                    if (readonly) {
                                        if (
                                            (field.code.replace(/\s+/g, '') === 'dueDate_' + this.props.template.code && fieldValue) ||
                                            (field.code.replace(/\s+/g, '') === 'reviewerDueDate_' + this.props.template.code && fieldValue)
                                        ) {
                                            fieldValue = getCorrectDateFormat(fieldValue);
                                            if (fieldValue.indexOf('Invalid') !== -1) fieldValue = '';
                                        } else {
                                            if (fieldType === 'TIME') fieldType = 'TIME_READONLY';
                                            if (['DATE', 'TIME_READONLY', 'DATE_TIME'].indexOf(fieldType) !== -1) {
                                                fieldValue = getDateTimeFormat4Field(fieldType, fieldValue);
                                            }
                                        }
                                        if (tempFieldType.indexOf('REF') !== -1) {
                                            let shortListName = tempFieldType.substr(4).toLowerCase();
                                            if (shortListName === 'reviewer' || shortListName === 'submitter') {
                                                shortListName = 'user';
                                            }
                                            let refOptions = JSON.parse(localStorage.getItem('list_' + shortListName)) || {};
                                            const storeKey = (tempFieldType, valueInd) => {
                                                const refArrayName = tempFieldType.toLocaleLowerCase() + 's';
                                                if (this.props[refArrayName]) {
                                                    saveToSessionStorageToCaseKey(
                                                        this.props.case.code,
                                                        this.props[refArrayName],
                                                        field,
                                                        fieldValue[valueInd],
                                                    );
                                                }
                                            };
                                            if (Array.isArray(fieldValue)) {
                                                let valueResult = [];
                                                for (let valueInd = 0; valueInd < fieldValue.length; valueInd += 1) {
                                                    if (typeof refOptions[fieldValue[valueInd]] !== 'undefined') {
                                                        valueResult.push(refOptions[fieldValue[valueInd]]);
                                                    } else {
                                                        if (
                                                            fieldValue[valueInd] !== 0 &&
                                                            fieldValue[valueInd] !== '' &&
                                                            typeof this.state.refAttempts[tempFieldType + '_' + fieldValue[valueInd]] === 'undefined'
                                                        ) {
                                                            let refAttempts = this.state.refAttempts;
                                                            refAttempts[tempFieldType + '_' + fieldValue[valueInd]] = 1;
                                                            this.setState({ refAttempts: refAttempts });
                                                            this.props
                                                                .getOne4Select(fieldValue[valueInd], tempFieldType.substr(4).toLowerCase())
                                                                .then(() => {
                                                                    storeKey(tempFieldType, valueInd);
                                                                });
                                                        } else if (
                                                            this.state.refAttempts[tempFieldType + '_' + fieldValue[valueInd]] > 0 &&
                                                            field['includeOther']
                                                        ) {
                                                            valueResult.push(fieldValue[valueInd]);
                                                        }
                                                    }
                                                }
                                                fieldValue = valueResult;
                                            } else if (
                                                typeof fieldValue === 'object' &&
                                                fieldValue != null &&
                                                typeof fieldValue['label'] !== 'undefined'
                                            ) {
                                                fieldValue = fieldValue['label'];
                                            } else {
                                                if (typeof refOptions[fieldValue] !== 'undefined') {
                                                    fieldValue = refOptions[fieldValue];
                                                }
                                                if (
                                                    typeof refOptions[fieldValue] === 'undefined' ||
                                                    !checkIfRefDataInCaseSessionStorage(this.props.case.code, field.code)
                                                )
                                                    if (
                                                        fieldValue !== 0 &&
                                                        fieldValue !== '' &&
                                                        typeof this.state.refAttempts[tempFieldType + '_' + fieldValue] === 'undefined'
                                                    ) {
                                                        let refAttempts = this.state.refAttempts;
                                                        refAttempts[tempFieldType + '_' + fieldValue] = 1;
                                                        this.setState({ refAttempts: refAttempts });
                                                        let promise = this.props.getOne4Select(fieldValue, tempFieldType.substr(4).toLowerCase());
                                                        if (promise) {
                                                            promise.then(() => {
                                                                let refArrayName = tempFieldType.toLocaleLowerCase() + 's';
                                                                if (this.props[refArrayName]) {
                                                                    saveToSessionStorageToCaseKey(
                                                                        this.props.case.code,
                                                                        this.props[refArrayName],
                                                                        field,
                                                                        fieldValue,
                                                                    );
                                                                }
                                                            });
                                                        }
                                                    }
                                            }
                                        }
                                        if (fieldValue && Array.isArray(fieldValue)) {
                                            fieldValue = fieldValue.join(', ');
                                        }
                                        fieldType = fieldType !== 'checkbox' ? 'label_field' : 'checkbox';
                                    } else {
                                        // only for not readonly fields
                                        if (field['includeOther']) {
                                            if (!Array.isArray(fieldValue)) {
                                                if (fieldValue) {
                                                    creatableOptions.push({ label: fieldValue, value: fieldValue });
                                                }
                                            } else {
                                                for (let opt_ind = 0; opt_ind < fieldValue.length; opt_ind += 1) {
                                                    if (fieldValue[opt_ind] === '') continue;
                                                    let found = false;
                                                    for (let fo_ind = 0; fo_ind < fieldOptions.length; fo_ind += 1) {
                                                        if (fieldOptions[fo_ind].value === fieldValue[opt_ind]) {
                                                            found = true;
                                                        }
                                                    }
                                                    if (found) continue;
                                                    creatableOptions.push({
                                                        label: fieldValue[opt_ind],
                                                        value: fieldValue[opt_ind],
                                                    });
                                                }
                                            }
                                        }
                                    }

                                    // BUILD FIELD SCRIPTS
                                    if (!field.onchange) field.onchange = [];
                                    if (!field.scriptBuilt) {
                                        field = scriptParse(this.props.case.code, field, this.props.template.code, this.props.roleCode);
                                        field.scriptBuilt = true;
                                    }

                                    if (fieldOptions && fieldOptions.length) {
                                        saveToSessionStorageToCaseKey(this.props.case.code, fieldOptions, field, fieldValue);
                                    } else {
                                        if (field.fieldType.indexOf('REF_') === 0) {
                                            let refArrayName = field.fieldType.toLocaleLowerCase() + 's';
                                            if (this.props[refArrayName]) {
                                                // if (field.code === 'facility_Sweden') {
                                                //     console.warn('TI::1', field, fieldValue, refArrayName);
                                                // }
                                                saveToSessionStorageToCaseKey(this.props.case.code, this.props[refArrayName], field, fieldValue);
                                            }
                                        }
                                    }

                                    //Field Validator
                                    let fieldValidationDtos = field.fieldValidationDtos;
                                    if (fieldValidationDtos.length > 0) {
                                        let validators = getFieldValidators(
                                            fieldText,
                                            field.code,
                                            fieldValidationDtos,
                                            this.props.template.code,
                                            this.props.values.fieldData,
                                        );
                                        Object.assign(specValidationMessages.softValidation, validators.softValidation);
                                        Object.assign(specValidationMessages.hardValidation, validators.hardValidation);
                                        /*specValidationMessages.hardValidation = {...specValidationMessages.hardValidation, ...validators.hardValidation}
                                        console.log(specValidationMessages, validators)*/
                                    }

                                    this.hardFields = specValidationMessages.hardValidation;

                                    // REQUIRED RULES
                                    let fieldRequired = field.requiredMatrix[this.props.matrixCode];
                                    if (field.requiredMatrixScript !== '' && field.requiredMatrixScript != null) {
                                        let requiredBuilt = onRequiredBuild(
                                            field.requiredMatrixScript,
                                            this.props.template.code,
                                            this.props.values.fieldData,
                                        );
                                        let requiredScript;
                                        try {
                                            // eslint-disable-next-line no-eval
                                            requiredScript = eval(requiredBuilt);
                                        } catch (e) {
                                            console.error('ERROR ' + e.name + ':' + e.message + '\n' + e.stack);
                                            console.warn(requiredBuilt);
                                        }
                                        fieldRequired =
                                            typeof requiredScript !== 'undefined'
                                                ? requiredScript && field.requiredMatrix[this.props.matrixCode]
                                                : field.requiredMatrix[this.props.matrixCode];
                                        if (field.requiredMatrix[this.props.matrixCode] && typeof requiredScript !== 'undefined' && !requiredScript) {
                                            this.notRequiredFields.push(convertStyledText(field.name));
                                        }
                                    }
                                    let className = fieldRequired && !readonly ? 'required-field' : 'text-bg';

                                    //field visibility scripts
                                    let fieldVisibility = field.visibilityMatrix[this.props.matrixCode];
                                    if (field.visibilityMatrixScript !== '' && field.visibilityMatrixScript != null) {
                                        let visibilityBuilt = onRequiredBuild(
                                            field.visibilityMatrixScript,
                                            this.props.template.code,
                                            this.props.values.fieldData,
                                        );
                                        let visibilityScript;
                                        // eslint-disable-next-line no-eval
                                        const evaluatedVisibilityBuilt = eval(visibilityBuilt);
                                        try {
                                            const isInDispatch = 'CW_DISPATCH' === this.props.case.currentStage.code
                                            if (isInDispatch && field.code && ['rationale', 'answer'].some(substring => field.code.toLowerCase().includes(substring))) {
                                              const value = this.props.values.fieldData[field.code];
                                              visibilityScript = (value && value !== '') || evaluatedVisibilityBuilt;
                                              className = !readonly && visibilityScript !== evaluatedVisibilityBuilt ? 'invalid-field' : className;
                                          } else if (isInDispatch && field.code && /question/i.test(field.code)) {

                                              const answerFieldCode = field.code.includes('Question')
                                                  ? field.code.replace('Question', 'Answer')
                                                  : field.code.replace('question', 'answer');
                                          
                                              const rationaleFieldCode = field.code.includes('Question')
                                                  ? field.code.replace('Question', 'Rationale')
                                                  : field.code.replace('question', 'rationale');
                                          
                                              const isVisible = (code) => {
                                                  const fieldValue = this.props.values.fieldData[code];
                                                  return !!(fieldValue && fieldValue !== '') || evaluatedVisibilityBuilt;
                                              };
                                              visibilityScript = isVisible(answerFieldCode) || isVisible(rationaleFieldCode);
                                              className = !readonly && visibilityScript !== evaluatedVisibilityBuilt ? 'invalid-field' : className;
                                          } else {
                                              visibilityScript = evaluatedVisibilityBuilt;
                                          }
                                        } catch (e) {
                                            console.error('ERROR ' + e.name + ':' + e.message + '\n' + e.stack);
                                            console.warn(visibilityBuilt);
                                        }
                                        fieldVisibility = typeof visibilityScript !== 'undefined' ?
                                          visibilityScript :
                                          field.visibilityMatrix[this.props.matrixCode];
                                        if (
                                            field.visibilityMatrix[this.props.matrixCode] &&
                                            typeof visibilityScript !== 'undefined' &&
                                            !visibilityScript
                                        ) {
                                            //this.hiddenFields.push(convertStyledText(field.name));
                                        }
                                    }
                                    let visibility = fieldVisibility && field.visibilityMatrix[this.props.matrixCode] ? 'block' : 'none';

                                    if (!clearable) className += ' notClearable';
                                    let validRules = field.requiredMatrix[this.props.matrixCode] && !readonly ? 'required' : '';
                                    // if (['DATE', 'TIME', 'DATE_TIME'].indexOf(fieldType) !== -1) { console.log(field.code, 'TI:2:fieldValue', fieldValue); }
                                    let feedback = field.updateSuccess;
                                    let tickMark = feedback ? ' ✔' : ' ❌';
                                    let color = feedback ? 'green' : 'red';
                                    let tickMarkHtml = feedback != null && <span style={{ color }}>{tickMark}</span>;

                                    fieldsBody.push(
                                        <React.Fragment key={field.code}>
                                            {newRow}
                                            <span key={field.code} className="caseFieldSpan" id={'vis-' + field.code} style={{ whiteSpace:'pre-line', display: visibility }}>
                                                <FormField
                                                    md={md}
                                                    xs={xs}
                                                    id={field.code}
                                                    type={fieldType}
                                                    label={fieldLabel}
                                                    tickMark={tickMarkHtml}
                                                    validLabel={fieldText}
                                                    title={helpText}
                                                    multi={field.allowMultiSelect}
                                                    className={className}
                                                    name={field.code}
                                                    disabled={readonly}
                                                    required={fieldRequired && !readonly}
                                                    valids={validRules}
                                                    validator={this.validator}
                                                    value={fieldValue}
                                                    data={fieldValue}
                                                    checked={fieldValue === 'true' || fieldValue === true}
                                                    creatable={field['includeOther']}
                                                    clearable={clearable}
                                                    options={fieldOptions}
                                                    fieldOptions={fieldOptions}
                                                    creatableOptions={creatableOptions}
                                                    rows={rows}
                                                    sorted={sorted}
                                                    min={
                                                        clearCode === 'turnAroundTime_' + this.props.template.code
                                                            ? TAT_MIN
                                                            : this.props.case.currentStage.name === 'Draft'
                                                            ? new Date(new Date().toDateString())
                                                            : new Date(new Date(this.props.case.submitDate).toDateString())
                                                    }
                                                    max={clearCode === 'turnAroundTime_' + this.props.template.code ? TAT_MAX : null}
                                                    onKeyDown={({ nativeEvent }) => {
                                                        // TAT max hack
                                                        if (
                                                            fieldType === 'INTEGER' &&
                                                            field &&
                                                            field.code &&
                                                            field.code.startsWith('turnAroundTime_')
                                                        ) {
                                                            let keyValue = parseInt(nativeEvent.key, 10);
                                                            if (
                                                                !this.isValidTATKey(nativeEvent.key) ||
                                                                (!isNaN(keyValue) && fieldValue + keyValue > TAT_MAX)
                                                            ) {
                                                                nativeEvent.preventDefault();
                                                            }
                                                        }
                                                    }}
                                                    onChange={(e, date) => {
                                                        const data = this.props.values.fieldData;
                                                        if (e && data) {
                                                            if (e.target) {
                                                                switch (e.target.type) {
                                                                    case 'BOOLEAN':
                                                                    case 'checkbox':
                                                                        if (e.target.checked != null && e.target.checked !== undefined) {
                                                                          data[e.target.id] = e.target.checked.toString();
                                                                        }
                                                                        break;
                                                                    case 'textarea':
                                                                        if (e.target.id.indexOf('CQC') === 0) {
                                                                            // save Questions
                                                                            let res_value = isJSON(this.props.values.fieldData[field.code])
                                                                                ? JSON.parse(this.props.values.fieldData[field.code])
                                                                                : { 0: this.props.values.fieldData[field.code] };
                                                                            res_value[e.target.id.substr(3)] = e.target.value;
                                                                            data[field.code] = JSON.stringify(res_value);
                                                                        } else if (e.target.id.indexOf('ACQC') === 0) {
                                                                            // save Answers
                                                                            let res_value = isJSON(this.props.values.fieldData[field.code])
                                                                                ? JSON.parse(this.props.values.fieldData[field.code])
                                                                                : { 0: this.props.values.fieldData[field.code] };
                                                                            res_value[e.target.id.substr(4)] = e.target.value;
                                                                            data[field.code] = JSON.stringify(res_value);
                                                                        } else {
                                                                            data[field.code] = e.target.value;
                                                                            data[e.target.id] = e.target.value;
                                                                        }
                                                                        break;
                                                                    default:
                                                                        data[e.target.id] = e.target.value;
                                                                }
                                                            } else if (e.value) {
                                                                data[field.code] = e.value;
                                                            } else if (date) {
                                                                if (fieldType === 'DATE') {
                                                                    // we should store and send the date part in UTC
                                                                    data[field.code] = moment(e).utc(true).format('YYYY-MM-DDTHH:mm:ss.000Z');
                                                                } else {
                                                                    data[field.code] = e;
                                                                }
                                                            } else if (Array.isArray(e)) {
                                                                let data_field_code = [];
                                                                for (let arr_n = 0; arr_n < e.length; arr_n += 1) {
                                                                    data_field_code.push(e[arr_n].value);
                                                                }
                                                                data[field.code] = JSON.stringify(data_field_code);
                                                            } else if (e) {
                                                                data[field.code] = e;
                                                            }
                                                            if (
                                                                fieldType === 'REF_CASE_TYPE' ||
                                                                (fieldType === 'select' && field.originalFieldCode === 'caseType')
                                                            ) {
                                                                data['turnAroundTime_' + this.props.template.code] = '';
                                                                let caseType = this.props.case_types.find((item) => item.code === data[field.code]);
                                                                let isChangesDates = false;
                                                                if (caseType) {
                                                                    let tat = caseType.tat ? caseType.tat : 1;
                                                                    if (tat !== '') {
                                                                        let { rdd, cdd } = onChangeTat(
                                                                            tat,
                                                                            this.props.case,
                                                                            this.props.case.businessCalendarDays,
                                                                        );
                                                                        data['turnAroundTime_' + this.props.template.code] = parseInt(tat, 10);
                                                                        data['dueDate_' + this.props.template.code] = cdd;
                                                                        data['reviewerDueDate_' + this.props.template.code] = rdd;
                                                                        isChangesDates = true;
                                                                        this.props.prepareCaseAttributes([
                                                                            { data: data[field.code] || '', code: field.code },
                                                                            { data: cdd || '', code: 'dueDate_' + this.props.template.code },
                                                                            { data: rdd || '', code: 'reviewerDueDate_' + this.props.template.code },
                                                                        ]);
                                                                    }
                                                                }
                                                                if (!isChangesDates) {
                                                                    this.props.prepareCaseAttributes([
                                                                        { data: data[field.code] || '', code: field.code },
                                                                    ]);
                                                                }
                                                            }
                                                            if (clearCode === 'turnAroundTime_' + this.props.template.code) {
                                                                let { rdd, cdd } = onChangeTat(
                                                                    data[e.target.id],
                                                                    this.props.case,
                                                                    this.props.case.businessCalendarDays,
                                                                );
                                                                data['dueDate_' + this.props.template.code] = cdd;
                                                                data['reviewerDueDate_' + this.props.template.code] = rdd;
                                                                this.props.prepareCaseAttributes([
                                                                    {
                                                                        data: cdd || '',
                                                                        code: 'dueDate_' + this.props.template.code,
                                                                    },
                                                                    { data: rdd || '', code: 'reviewerDueDate_' + this.props.template.code },
                                                                ]);
                                                            }
                                                            if (clearCode === 'dueDate_' + this.props.template.code) {
                                                                let { tat, rdd } = onChangeCDD(
                                                                    data[field.code],
                                                                    this.props.case,
                                                                    this.props.case.businessCalendarDays,
                                                                );
                                                                data['turnAroundTime_' + this.props.template.code] = parseInt(tat, 10);
                                                                data['reviewerDueDate_' + this.props.template.code] = rdd;
                                                                this.props.prepareCaseAttributes([
                                                                    {
                                                                        data: data[field.code] || '',
                                                                        code: 'dueDate_' + this.props.template.code,
                                                                    },
                                                                    { data: rdd || '', code: 'reviewerDueDate_' + this.props.template.code },
                                                                ]);
                                                            }
                                                            if (clearCode === 'reviewerDueDate_' + this.props.template.code) {
                                                                let { tat, cdd } = onChangeRDD(
                                                                    data[field.code],
                                                                    data['turnAroundTime_' + this.props.template.code],
                                                                    this.props.case,
                                                                    this.props.case.businessCalendarDays,
                                                                );
                                                                data['turnAroundTime_' + this.props.template.code] = tat;
                                                                data['dueDate_' + this.props.template.code] = cdd;
                                                                this.props.prepareCaseAttributes([
                                                                    {
                                                                        data: cdd || '',
                                                                        code: 'dueDate_' + this.props.template.code,
                                                                    },
                                                                    {
                                                                        data: data[field.code] || '',
                                                                        code: 'reviewerDueDate_' + this.props.template.code,
                                                                    },
                                                                ]);
                                                            }
                                                            if (
                                                                fieldType === 'REF_CASE_TYPE' ||
                                                                clearCode === 'dueDate_' + this.props.template.code ||
                                                                clearCode === 'turnAroundTime_' + this.props.template.code ||
                                                                clearCode === 'reviewerDueDate_' + this.props.template.code
                                                            ) {
                                                                setTimeout(() => {
                                                                    this.props.setNeedToReloadAssignments();
                                                                }, 100);
                                                            }
                                                            if (field.onchange && Array.isArray(field.onchange) && field.onchange.length > 0) {
                                                                for (let ocKey = 0; ocKey < field.onchange.length; ocKey += 1) {
                                                                    let ocAction = field.onchange[ocKey];
                                                                    try {
                                                                        // eslint-disable-next-line no-eval
                                                                        eval(ocAction);
                                                                    } catch (e) {
                                                                        console.error('ERROR ' + e.name + ':' + e.message + '\n' + e.stack);
                                                                        console.warn(ocAction);
                                                                    }
                                                                }
                                                            }
                                                            saveCaseSessionStorageData(this.props.case.code, 'fields', data);

                                                            // UPDATE MAPPING CASE ATTRIBUTES
                                                            if (
                                                                [
                                                                    'caseCode_' + this.props.template.code,
                                                                    'caseNumber_' + this.props.template.code,
                                                                    'caseType_' + this.props.template.code,
                                                                    'caseLevel_' + this.props.template.code,
                                                                    'determination_' + this.props.template.code,
                                                                    'serviceType_' + this.props.template.code,
                                                                    'dueDate_' + this.props.template.code,
                                                                    'reviewerDueDate_' + this.props.template.code,
                                                                    'turnAroundTime_' + this.props.template.code,
                                                                ].indexOf(clearCode) !== -1
                                                            ) {
                                                                if (
                                                                    clearCode !== 'turnAroundTime_' + this.props.template.code &&
                                                                    clearCode !== 'dueDate_' + this.props.template.code &&
                                                                    clearCode !== 'reviewerDueDate_' + this.props.template.code &&
                                                                    clearCode !== 'caseType_' + this.props.template.code
                                                                ) {
                                                                    this.props.prepareCaseAttributes([
                                                                        { data: data[field.code] || '', code: field.code },
                                                                    ]);
                                                                } else {
                                                                    setTimeout(() => {
                                                                        this.dispatchValidState();
                                                                    }, 100);
                                                                }
                                                                saveCaseSessionStorageData(this.props.case.code, 'attributes', {
                                                                    data: data,
                                                                    code: field.code,
                                                                });
                                                            } else {
                                                                this.props.prepareCaseAttributes([{}]);
                                                                saveCaseSessionStorageData(this.props.case.code, 'attributes', {});
                                                            }
                                                            if (fieldType.indexOf('REF') === 0 || fieldType === 'select') {
                                                                setTimeout(() => {
                                                                    this.dispatchValidState();
                                                                }, 100);
                                                            }
                                                        } else {
                                                            // clear select values
                                                            if (e == null) {
                                                                if (fieldType === 'REF_CASE_DETERMINATION' || fieldType === 'REF_SERVICE_TYPE') {
                                                                    data[field.code] = '';
                                                                    this.props.prepareCaseAttributes([
                                                                        { data: data[field.code] || '', code: field.code },
                                                                    ]);
                                                                    //localStorage.setItem('attributes', JSON.stringify({data: data, code: field.code}));
                                                                    saveCaseSessionStorageData(this.props.case.code, 'attributes', {
                                                                        data: data,
                                                                        code: field.code,
                                                                    });
                                                                    setTimeout(() => {
                                                                        this.dispatchValidState();
                                                                    }, 200);
                                                                } else if (fieldType.indexOf('REF') === 0 || fieldType === 'select') {
                                                                    data[field.code] = '';
                                                                    //localStorage.setItem('fields', JSON.stringify(data));
                                                                    saveCaseSessionStorageData(this.props.case.code, 'fields', data);
                                                                    this.props.prepareFields(data);
                                                                    this.dispatchValidState();
                                                                } else if (fieldType === 'DATE' || fieldType === 'DATE_TIME') {
                                                                    // clear date fields
                                                                    data[field.code] = null;
                                                                    saveCaseSessionStorageData(this.props.case.code, 'fields', data);
                                                                }
                                                            }
                                                        }
                                                    }}
                                                    onBlur={this.dispatchValidState}
                                                    allowDate={
                                                        getSelectFieldValue(fieldType) + '' === 'DATE' ||
                                                        getSelectFieldValue(fieldType) + '' === 'YEAR' ||
                                                        getSelectFieldValue(fieldType) + '' === 'DATE_TIME'
                                                    }
                                                    allowTime={
                                                        getSelectFieldValue(fieldType) + '' === 'TIME' ||
                                                        getSelectFieldValue(fieldType) + '' === 'DATE_TIME'
                                                    }
                                                    allowYear={getSelectFieldValue(fieldType) + '' === 'YEAR'}
                                                    fromCase={true}
                                                />
                                                <span style={{ display: 'none' }}>
                                                    {field.script !== '' ? (
                                                        <React.Fragment>
                                                            if (!window.scripts) window.scripts = {}; window.scripts[field.code] = function(){' '}
                                                            {setTimeout(() => {
                                                                try {
                                                                    // eslint-disable-next-line no-eval
                                                                    if(sessionStorage.getItem(getSessionStorageKey(this.props.case.code))) {
                                                                        eval(field.script);
                                                                    }
                                                                } catch (e) {
                                                                    console.error('ERROR ' + e.name + ':' + e.message + '\n' + e.stack);
                                                                    console.warn(field.script);
                                                                }
                                                            }, 200)}
                                                        </React.Fragment>
                                                    ) : (
                                                        ''
                                                    )}
                                                </span>
                                            </span>
                                            {endOfRow}
                                        </React.Fragment>,
                                    );
                                }
                            }
                        }
                    }

                    const disabledForm = this.props.isEditable ? {} : { pointerEvents: 'none' };
                    
                    if (
                        isAssignSection(section) &&
                        (this.props.matrixCode.indexOf('DISPATCH#ROLE_ADMIN') !== -1 ||
                            this.props.matrixCode.indexOf('DISPATCH#ROLE_STAFF') !== -1 ||
                            this.props.matrixCode.indexOf('REVIEW#ROLE_ADMIN') !== -1 ||
                            this.props.matrixCode.indexOf('REVIEW#ROLE_STAFF') !== -1)
                    ) {
                        let showEdit = !this.props.isLocked && (
                            this.props.matrixCode.indexOf('DISPATCH#ROLE_ADMIN') !== -1 ||
                            this.props.matrixCode.indexOf('DISPATCH#ROLE_STAFF') !== -1);
                        sectionsBody.push(
                            <div className="row" key={section.code} id={sectionName} style={disabledForm}>
                                <div className="col-md-12">
                                    <AssignReviewers showEdit={showEdit} name={sectionName} />
                                </div>
                            </div>,
                        );
                        /*} else if (isAttachSection(section)) {
                         sectionsBody.push(
                         <div className="row" key={section.code} id={sectionName}>
                         <div className="col-md-12">
                         <AttachFile/>
                         </div>
                         </div>
                         );*/
                    } else if (isAttachSection(section) || isMedicalRecordsSection(section)) {
                        sectionsBody.push(
                            <div className="row" key={section.code} id={sectionName}>
                                <div className="col-md-12">
                                    <MedicalRecords type="MEDICAL_RECORDS" name={sectionName} />
                                </div>
                            </div>,
                        );
                    } else if (isReportSection(section)) {
                        sectionsBody.push(
                            <div className="row" key={section.code} id={sectionName}>
                                <div className="col-md-12">
                                    <Reports type="REPORTS" name={sectionName} />
                                </div>
                            </div>,
                        );
                    } else if (isInternalSection(section)) {
                        sectionsBody.push(
                            <div className="row" key={section.code} id={sectionName}>
                                <div className="col-md-12">
                                    <InternalCaseFiles type="INTERNAL_CASE_FILES" name={sectionName} />
                                </div>
                            </div>,
                        );
                    } else if (isOwnerSection(section)) {
                        sectionsBody.push(
                            <div className="row" key={section.code} id={sectionName} style={disabledForm}>
                                <div className="col-md-12">
                                    <OwnersList name={sectionName} />
                                </div>
                            </div>,
                        );
                    } else if (isFinanceSection(section) && userCanSeeFinance(this.props.roleCode, this.props.case.currentStage)) {
                        let patientCase = this.props.case;
                        let userRole = this.props.roleCode;
                        sectionsBody.push(
                            <div className="row" key={section.code} id={sectionName}>
                                <div className="col-md-12">
                                    <FinanceSection
                                        patientCase={patientCase}
                                        userRole={userRole}
                                        name={section.displayName[this.state['lang']]}
                                        legacyValidator={this.validator}
                                        dispatchValidState={this.dispatchValidState}
                                        templateCode={this.props.template.code}
                                        isClosedCase={patientCase.currentStage.code === 'CW_CLOSED'}
                                        isLocked={this.props.isLocked}
                                    />
                                </div>
                            </div>,
                        );
                    } else if (isCaseCommentsSection(section) && userCanSeeComments(this.props.roleCode)) {
                        sectionsBody.push(
                            <div className="row" key={section.code} id={sectionName}>
                                <div className="col-md-12">
                                    <CaseCommentsForm caseEntityCode={this.props.case.code} section={section} />
                                </div>
                            </div>,
                        );
                    } else {
                        const resubmitReasonCode = this.props.fields.find(field => field.code.includes('resubmitReason')).code;
                        const resubmitNotesCode = this.props.fields.find(field => field.code.includes('resubmitNotes')).code;
                        const hasResubmitReasonValues = this.props.values.fieldData[resubmitReasonCode] != null && this.props.values.fieldData[resubmitReasonCode] != "";
                        const hasResubmitNotesValues = this.props.values.fieldData[resubmitNotesCode] != null && this.props.values.fieldData[resubmitNotesCode] != "";
                        const collapsed = section.code.includes('reopen') && !hasResubmitReasonValues && !hasResubmitNotesValues;
                        // console.log('sectionName', sectionName);
                        sectionsBody.push(
                            <Row className="row" key={section.code} id={sectionName} style={disabledForm}>
                                <Col md={12}>
                                    <CollapseBox title={sectionName} collapsedDefault={collapsed}>
                                        <div className="form-group withoutPre">{fieldsBody}</div>
                                    </CollapseBox>
                                </Col>
                            </Row>,
                        );
                    }
                }

                if (this.props.caseIsCreated) {
                    this.props.setCaseIsCreated(false);
                    if (data4prepare.length > 0) {
                        this.updateCaseAttributes(data4prepare);
                    }
                }
            }
            // this.hiddenSections = Array.prototype.slice
            //     .call(document.getElementsByClassName('row'))
            //     .filter((item) => item && item.style.display === 'none')
            //     .map((item) => item.id);

            this.visibleSections = Array.prototype.slice
                .call(document.getElementsByClassName('row'))
                .filter((item) => item && item.style.display !== 'none')
                .map((item) => item.id);

            let hidFields = this.props.fields.filter(({ code, sectionName }) => {
                const element = document.getElementById('vis-' + code);
                //console.log('hide',sectionName, element, time)
                return (
                    (element && element.style.display === 'none') ||
                    this.hiddenSections.includes(sectionName) ||
                    !this.visibleSections.includes(sectionName)
                );
            });
            this.prevHiddenFields = this.hiddenFields ? JSON.parse(JSON.stringify(this.hiddenFields)) : [];
            this.prevHiddenSections = this.hiddenSections ? JSON.parse(JSON.stringify(this.hiddenSections)) : [];

            /*console.log({
    hiddenFields: this.hiddenFields && this.hiddenFields.length,
    hiddenFieldsPrev: this.prevHiddenFields.length,
    visibleSections: this.visibleSections.length,
    prevHiddenFields: this.prevHiddenFields, vs: this.visibleSections
});*/
            // console.log(this.prevHiddenFields.length, this.hiddenFields.length);
            if (!this.dispatcherCounter) this.dispatcherCounter = 0;
            this.hiddenFields = hidFields.map((item) => convertStyledText(item.name));
            if (!isEqual(this.hiddenFields, this.prevHiddenFields) && this.dispatcherCounter < 10) {
                this.dispatchValidState();
                this.dispatcherCounter += 1;
            }

            // SECTIONS
            for (let i = 0; i < this.props.sections.length; i += 1) {
                let section = this.props.sections[i];
                let sectionName = section.displayName[this.state.lang];

                //console.log('sectionName', sectionName);

                if (isFinanceSection(section) && (this.props.case.currentStage.code === 'CW_DRAFT' || isClient(this.props.roleCode))) {
                    this.hiddenSections.push(section);
                } else if (isSystemSection(section)) {
                    const index = this.hiddenSections.indexOf(sectionName);
                    if (index > -1) {
                        this.hiddenSections.splice(index, 1);
                    }
                    continue;
                }
                //if (this.hiddenSections.includes(sectionName)) continue;
                let countVisible = 0;
                for (let j = 0; j < section['fieldInstances'].length; j += 1) {
                    let field = section['fieldInstances'][j];
                    let found = false;
                    if (document.getElementById('vis-' + field)) {
                        for (let key = 0; key < hidFields.length; key += 1) {
                            if (hidFields[key].code === field) {
                                if (document.getElementsByName(field)[0]) {
                                    // TODO: there is at least one Select item under the section
                                    if (document.getElementById('vis-' + field).style.display === 'none') {
                                        //console.log('field found 1', field);
                                        found = true;
                                        break;
                                    }
                                } else {
                                    //console.log('field found 2', field);
                                    if (document.getElementById('vis-' + field).style.display === 'none') {
                                        found = true;
                                        break;
                                    }
                                }
                            }
                        }
                        if (!found) {
                            countVisible += 1;
                        }
                    }
                }
                //console.log('countVisible:::::', sectionName, countVisible);
                const sectionDOMElement = document.getElementById(sectionName);
                if (countVisible === 0) {
                    // there is no visible field under section
                    if (sectionDOMElement && sectionDOMElement.style.display !== 'none') {
                        sectionDOMElement.style.display = 'none';
                        const sectionLink = document.getElementById(sectionName + 'LINK');
                        if (sectionLink) {
                            sectionLink.style.display = 'none';
                        }
                        this.hiddenSections.push(sectionName);
                        this.props.isAllValid(this.getInnerValidationState());
                    }
                } else {
                    if (sectionDOMElement && this.prevHiddenSections.includes(sectionName)) {
                        const index = this.hiddenSections.indexOf(sectionName);
                        if (index > -1) {
                            this.hiddenSections.splice(index, 1);
                        }
                        sectionDOMElement.style.display = '';
                        const sectionLink = document.getElementById(sectionName + 'LINK');
                        if (sectionLink) {
                            sectionLink.style.display = '';
                        }
                    }
                }
            }

            const fieldToId = this.props.fields.reduce((labelToId, field) => {
                const label = convertStyledText(field.name);
                return {
                    ...labelToId,
                    [label]: field.code,
                };
            }, {});

            if(this.visibleSections.length > 0) {
              const questionCountTotal = this.props.fields
              .filter((item) => !hidFields.includes(item))
              .filter((item) => this.visibleSections.includes(item.sectionName))
              .filter((item) => item.question)
              .length
              .toString();
              
              saveCaseSessionStorageData(this.props.case.code, 'values.questionCountTotal_' + this.props.template.code, questionCountTotal);
            }

            let errorMessages = Object.keys(this.validator.errorMessages)
                .filter((item) => !this.hiddenFields.includes(item) && !this.notRequiredFields.includes(item))
                .map((field) => ({
                    id: fieldToId[field],
                    field,
                    message: this.validator.errorMessages[field],
                }));
            errorMessages = errorMessages.concat(specValidationMessages.hardValidation);
            // console.log(errorMessages)

            runScripts();

            // if (this.props.caseMessageTime !== '') {
            //     setTimeout(() => {
            //         this.props.setCaseMessage({
            //             text: '',
            //         });
            //     }, parseInt(this.props.caseMessageTime, 10));
            // }
            //console.groupEnd();
            return (
                <section className="content user-profile-content">
                    <div className="row">
                        <div className="col-additional-nav">
                            <div className="box box-primary box-additional-nav">
                                <div className="box-header box-header_additional-nav with-border">
                                    <h3 className="box-title text-blue">
                                        <strong>MedRev №</strong> {this.props.case.caseId}
                                    </h3>
                                </div>
                                <div className="box-body box-body_additional-nav">
                                    <ul className="additional-nav">{sectionLinks}</ul>
                                </div>
                            </div>
                            {!isReviewer(this.props.roleCode) ? <NavBar disabled={isClient(this.props.roleCode)} /> : ''}
                        </div>

                        <div className={!isReviewer(this.props.roleCode) ? 'hasHeaderNavBar ' : ''}>
                            <div className="col-content">
                                <SystemMessage
                                    shown={this.props.isForceEditable && this.props.isLocked && !this.props.isLockedByOtherUser}
                                    type={'warning'}
                                    message={this.props.isForceEditable && this.props.isLocked && !this.props.isLockedByOtherUser ? 'This case is currently open in another window, that\'s why it\'s in read-only mode. Editing the same case in multiple windows may lead to inconsistencies. To avoid this, please ensure that only one window is used for editing at a time. Please close the other window and refresh this page or click on the Force Edit button.' : null}
                                    time={this.props.caseMessageTime}
                                    top={isReviewer(this.props.roleCode) ? '80px' : '145px'}
                                />
                                <SystemMessage
                                    shown={!this.props.isForceEditable && this.props.isLocked && this.props.isLockedByOtherUser}
                                    type={'warning'}
                                    message={!this.props.isForceEditable && this.props.isLocked && this.props.isLockedByOtherUser ? 'This case is currently in use by someone else, that\'s why it\'s in read-only mode. You can not take over editing this case. If you feel this is an error, please contact your Administrator.' : null}
                                    time={this.props.caseMessageTime}
                                    top={isReviewer(this.props.roleCode) ? '80px' : '145px'}
                                />
                                <SystemMessage
                                    shown={this.props.isForceEditable && this.props.isLocked && this.props.isLockedByOtherUser}
                                    type={'warning'}
                                    message={this.props.isForceEditable && this.props.isLocked && this.props.isLockedByOtherUser ? 'This case is currently in use by someone else, that\'s why it\'s in read-only mode. Editing the same case in multiple windows may lead to inconsistencies. You can take over editing this case and lock the other user out by pressing the Force Edit button.' : null}
                                    time={this.props.caseMessageTime}
                                    top={isReviewer(this.props.roleCode) ? '80px' : '145px'}
                                />
                                <SystemMessage
                                    shown={this.props.hasAnyCaseFieldUpdateError}
                                    type={'error'}
                                    message={this.props.hasAnyCaseFieldUpdateError ? 'An error occurred communicating with the server. Submitting this Case now is not possible. Please refresh the page and check for any missing fields before submitting again.' : null}
                                    time={this.props.caseMessageTime}
                                    top={isReviewer(this.props.roleCode) ? '80px' : '145px'}
                                />
                                <SystemMessage
                                    shown={this.props.caseMessage && !this.props.showNotValid && !this.props.error}
                                    type={this.props.caseMessageType}
                                    message={this.props.caseMessage}
                                    time={this.props.caseMessageTime}
                                    top={isReviewer(this.props.roleCode) ? '80px' : '145px'}
                                />
                                <SystemMessage
                                    shown={this.props.caseBackendMessage}
                                    type={'error'}
                                    message={this.props.caseBackendMessage}
                                    time={this.props.caseMessageTime}
                                    top={isReviewer(this.props.roleCode) ? '80px' : '145px'}
                                />
                                <SystemMessage
                                    style={{ marginBottom: '20px', marginTop: '-20px' }}
                                    shown={this.props.showNotValid}
                                    type="error"
                                    validationMessages={errorMessages}
                                    time={this.props.caseMessageTime}
                                    top={isReviewer(this.props.roleCode) ? '80px' : '145px'}
                                />
                                {specValidationMessages.softValidation ? (
                                    <SystemMessage
                                        style={{ marginBottom: '20px', marginTop: '-20px' }}
                                        shown={specValidationMessages.softValidation.length > 0}
                                        type="warning"
                                        time={this.props.caseMessageTime}
                                        validationMessages={specValidationMessages.softValidation}
                                        top={isReviewer(this.props.roleCode) ? '80px' : '145px'}
                                    />
                                ) : (
                                    ''
                                )}

                                {sectionsBody}
                            </div>
                        </div>
                    </div>
                </section>
            );
        }
    }
}

const mapStateToProps = (state) => {
    // console.log('TemplatesInner-CASE', state.app.cases.editedCase);
    // console.log('TemplatesInner-SECTION', state.app.cases.sections);
    const isEditable = isClient(state.app.auth.user.roleCode) ? state.app.cases.editedCase.currentStage.code === 'CW_DRAFT' : true;
    return {
        //While finance calculate is running. Case editing should be in readonly mode.
        isFinancesLoading:  state.app.finances.isLoading,
        isLocked: state.app.cases.caseLockInfo.locked,
        isForceEditable: state.app.cases.caseLockInfo.forceEditable,
        isLockedByOtherUser: state.app.cases.caseLockInfo.lockedByOtherUser,
        hasAnyCaseFieldUpdateError: state.app.cases.editedCase.caseFieldUpdateError,
        isEditable: isEditable,
        prepareData: state.app.cases.preparedFields,
        preparedAttributes: state.app.cases.preparedAttributes,
        user: state.app.auth.user,
        users: state.app.users.users,
        my_permissions: state.app.users.my_permissions,
        acceptedUser: typeof state.app.case_assign.accepted.user !== 'undefined' ? state.app.case_assign.accepted.user : '',
        roleCode: state.app.auth.user.roleCode,
        matrixCode:
            typeof state.app.cases.editedCase.currentStage.code !== undefined
                ? state.app.cases.editedCase.currentStage.code.replace('SW_', 'CW_') + '#' + state.app.auth.user.roleCode
                : '',
        case: state.app.cases.editedCase,
        files: state.app.cases.files,
        reports: state.app.cases.reports,
        company: state.app.companies.editedCompany,
        // physicianCodes: state.app.companies.editedCompany.privatePhysicianCodes,
        // facilityCodes: state.app.companies.editedCompany.facilityCodes,
        error: state.app.cases.error,
        caseBackendMessage: state.app.cases.caseBackendMessage,
        caseListError: state.app.case_list.error,
        template: state.app.templates,
        fields: state.app.cases.fields,
        ref_fields: state.app.cases.ref_fields,
        sections: state.app.cases.sections,
        values: state.app.cases.values,
        assignmentDetails: state.app.case_assign.appliedReviewers,
        case_types: state.app.case_types.types,
        reviewer_types: state.app.reviewerTypes.list,
        showNotValid: state.app.cases.showNotValidMessage,
        caseMessage: state.app.cases.caseMessage,
        caseMessageType: state.app.cases.caseMessageType,
        caseMessageTime: state.app.cases.caseMessageTime,
        caseIsCreated: state.app.cases.caseIsCreated,

        // don't change next names!!!
        ref_patients: state.app.patients.data,
        ref_body_parts: state.app.bodyParts.data,
        ref_billing_tiers: state.app.billingTiers.data,
        ref_case_types: state.app.case_types.types,
        ref_case_levels: state.app.case_types.levels,
        ref_case_determinations: state.app.case_determination.data,
        ref_currencys: state.app.currencies.data,
        ref_companys: state.app.companies.data,
        ref_medical_diagnosiss: state.app.diagnoses.data,
        ref_facilitys: state.app.facilities.data,
        ref_facility_types: state.app.facilities.types,
        ref_genders: state.app.gender.data,
        ref_physicians: state.app.physician.data,
        ref_medical_services: state.app.procedures.data,
        ref_reviewers: state.app.users.users.filter((item) => isReviewer(item.roleCode)),
        ref_service_types: state.app.serviceType.data,
        ref_sides: state.app.side.data,
        ref_specialtys: state.app.specialties.data,
        ref_medical_codes: state.app.scSystem.data,
        ref_medical_coding_systems: state.app.mcSystem.data,
        ref_submitter: state.app.users.users,
        ref_languages: state.app.languages.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCase: (code) => dispatch(getCase(code)),
        //getCompanyProfile: (code) => dispatch(getCompanyProfile(code)),
        // modalOpenEdit: () => dispatch(openEditModal()),
        updateCaseValues: (code) => dispatch(updateCaseValues(code)),
        removeLock: (code, tabTimeStamp) => dispatch(removeCaseLocking(code, tabTimeStamp)),
        prepareFields: (data) => dispatch(prepareFields(data)),
        prepareCaseAttributes: (data) => dispatch(prepareCaseAttributes(data)),
        updateCase: (data) => dispatch(editCase(data)),
        isAllValid: (valid) => dispatch(isAllValid(valid)),
        showNotValidMessage: (data) => dispatch(showNotValidMessage(data)),
        changeCaseOwner: (stage, owner) => dispatch(changeCaseOwner(stage, owner)),
        // loadCaseData: () => dispatch({ type: 'FETCH_CASE_LOADING' }),
        setSubmitAfterValidation: () => dispatch(setSubmitAfterValidation(true)),

        setCaseMessage: (data) => dispatch(setCaseMessage(data)),
        getCaseTemplate: (code) => dispatch(getCaseTemplate(code)),
        getCaseValues: (code) => dispatch(getCaseValues(code)),
        getPatientCaseLock: (code) => dispatch(getPatientCaseLock(code)),
        getCaseFiles: (code) => dispatch(getCaseFiles(code)),
        getCaseAssignment: (code) => dispatch(getCaseAssignment(code)),
        getActiveCountries: () => dispatch(getActiveCountries()),
        getActiveStates: () => dispatch(getActiveStates()),
        getOne4Select: (code, entity) => dispatch(getOne4Select(code, entity)),
        getAll: (entity) => dispatch(getAll(entity)),
        setCaseIsCreated: (data) => dispatch(setCaseIsCreated(data)),
        setNeedToReloadAssignments: () => dispatch(setNeedToReloadAssignments(true)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplatesInner);
