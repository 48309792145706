import connect from "react-redux/es/connect/connect";
import {updateCaseLocking} from '../../../redux/actions/cases/caseActions'
import CaseForceOpenButton from "./CaseForceEditButton";


const mapState = (state) => {
    return {
        className: 'btn-default margin-left-5',
        isVisible: true,
        icon: 'fa-edit',
        title: 'Force edit',
        needConfirmation: true,
        disabled: state.app.cases.editedCase.code === 'CW_CLOSED' || !state.app.cases.caseLockInfo.forceEditable || state.app.cases.loading,
    }
};

const mapDispatchToProps = dispatch => {
    const response =  {
        onClick: () => dispatch(updateCaseLocking(true)),
    }

  return response;
};

export default connect(mapState, mapDispatchToProps)(CaseForceOpenButton);
