import React, { useContext } from 'react';
import {
  getReportsFromSection,
  uploadReport,
} from '../../../redux/actions/cases/caseReportsActions';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import LoadingIndicator from '../../../components/LoadingIndicator';

import SystemMessage from '../../../components/SystemMessage';
import { ClientSideDataTable } from 'components/Datatable';
import CollapseBox from 'components/CollapseBox/CollapseBox';

import { useDispatch, useSelector } from 'react-redux';
import { LegacyContext } from 'components/AppInitializer';
import { getCase, getCaseFiles } from 'redux/actions/cases/caseActions';
import { openFilesModal, saveDeletedCode } from 'redux/action-creators/users';

/* eslint-disable-next-line */
const linkReportFormatter = ({ dispatch, canModifyCase }) => (cell, row) => {
  if (canModifyCase) {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        onClick={(event) => {
          event.preventDefault();
          dispatch(getReportsFromSection(row.code, true, false));
        }}
        title={cell}
      >
        {JSON.stringify(row.latestReport, null, 2) ? <strong>{cell}</strong> : cell}
        </a>
    );
  } else {
    return cell;
  }
};

const Reports = ({
  name,
  match: {
    params: { id: caseCode },
  },
}) => {
  const dispatch = useDispatch();
  const { translate } = useContext(LegacyContext);

  const {
    reports,
    loading,
    isLocked,
    caseFileErrorMessage,
    canUploadReport,
    canDeleteReport,
    canModifyCase,
  } = useSelector((state) => {
    const { cases } = state.app;
    return {
      isLocked: state.app.cases.caseLockInfo.locked,
      caseFileErrorMessage: cases.caseFileErrorMessage,
      reports: cases.reports,
      loading: cases.loading_reports,
      caseReports: state.app.grid.caseReports,
      canUploadReport: state.app.users.my_permissions.includes('UPLOAD_REPORT'),
      canModifyCase: state.app.users.my_permissions.includes('MODIFY_CASES'),
      canDeleteReport: state.app.users.my_permissions.includes('DELETE_REPORT'),
    };
  });

  const columns = {
    name: {
      label: translate('common.form.internal_case_files_name'),
      colStyle: { width: 'auto' },
      format: linkReportFormatter({ dispatch, canModifyCase }),
    },
    date: {
      label: translate('common.form.date_generated'),
      colStyle: { width: '200px' },
    },
  };

  const onSaveFiles = (savedFiles) => {
    let files = savedFiles.target.files;
    for (let i = 0; i < files.length; i += 1) {
      dispatch(
        uploadReport({
          code: caseCode,
          file: files[i],
        }),
      ).then(() => {
        dispatch(getCase(caseCode)).then(() => {
          dispatch(getCaseFiles(caseCode));
        });
      });
    }
  };

  const onDeactivateReport = (code) => {
    dispatch(saveDeletedCode(code, 'report'));
    dispatch(openFilesModal());
  };

  const uploadButton = canUploadReport && !isLocked && (
    <label
      className="upload_button btn btn-default margin-left-5 pull-right"
      title="Generate"
      style={{
        marginRight: '55px',
        marginTop: -6,
      }}
    >
      <input
        type="file"
        style={{ display: 'none' }}
        onChange={onSaveFiles}
        accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,.doc,.docs,.pdf"
      />
      {translate('common.case.upload_report')}
    </label>
  );

  // eslint-disable-next-line react/prop-types
  const actions = ({ code }) => {
    if (!canDeleteReport) return null;
    return (
      <button
        disabled={isLocked}
        className="btn btn-default margin-left-5"
        onClick={() => {
          onDeactivateReport(code);
        }}
      >
        <i className="fa fa-times" />
      </button>
    );
  };

  markLatestReport(reports);
  
  return (
    <CollapseBox
      titleIcon="file-pdf-o"
      title={name}
      additionalHeaderItems={uploadButton}
    >
      {loading && <LoadingIndicator height={50} />}

      {caseFileErrorMessage && (
        <SystemMessage
          shown={caseFileErrorMessage}
          type="error"
          message="An unrecoverable error occurred while generating the report."
          top="7em"
        />
      )}
      
      <ClientSideDataTable
          data={reports}
          columns={columns}
          loadingPlaceholderCount={3}
          customActions={actions}
          actionsTitle="Remove"
          initialSettings={{
            sortBy: "date",
            sortDirection: "desc",
          }}
        />
    </CollapseBox>
  );
};


const markLatestReport = (reports) => {
  if (reports.length === 0) return; 

  let latestIndex = 0;
  let latestDate = reports[0].date_original;

  reports.forEach((report, index) => {
    const currentDate = report.date_original;
    if (currentDate > latestDate) {
      latestDate = currentDate;
      latestIndex = index;
    }
  });

  reports[latestIndex].latestReport = true;
};

Reports.contextTypes = {
  translate: PropTypes.func,
};

Reports.propTypes = {
  name: PropTypes.string.isRequired,
  match: PropTypes.object,
};

export default withRouter(Reports);
