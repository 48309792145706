import React, { useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { mfaAccessCodeForLogin, mfaOptionsForLogin, mfaPasswordChange } from '../../../redux/actions/authActions';
import useInterval from './useInterval';
import {
  AUTH_TYPE,
  Button,
  delayedApiCall,
  err,
  GoToLogin,
  log,
  PasswordHint,
  ReCaptcha,
  TabNav,
  TabNavItem,
  TWAuthLayout,
  TWFormElementAccessCode,
  TWFormElementAuthenticatorCode,
  TWFormElementEmail,
  TWFormElementPassword,
  warn,
} from './utils';

const FORM_STATE = {
  EDIT: 'EDIT',
  PASSWORD: 'PASSWORD',
  ACCESS_CODE: 'ACCESS_CODE',
  SUCCESS: 'SUCCESS',
};

export const PasswordExpiration2FAForm = () => {
  const dispatch = useDispatch();
  const emailRef = useRef();
  const passwordRef = useRef();
  const newPasswordRef = useRef();
  const repeatNewPasswordRef = useRef();
  const accessCodeRef = useRef();
  const authenticatorCodeRef = useRef();
  const recaptchaRef = useRef();

  const [state, setStateOriginal] = useState({
    formState: FORM_STATE.EDIT,
    isLoading: false,
    needRecaptcha: false,
    needMfa: false,
    authType: null,
    extEmailError: '',
    extPasswordError: '',
    extNewPasswordError: '',
    extRepeatNewPasswordError: '',
    canShowEmailTab: false,
    canShowAuthenticatorTab: false,
    canShowSmsTab: false,
  });

  const setState = useCallback(
    (newState) => {
      setStateOriginal({ ...state, ...newState });
    },
    [setStateOriginal, state],
  );

  const ctaMfaOptions = () => {
    const email = emailRef.current;
    if (!email || !email.isValid()) {
      return;
    }
    const currentEmail = email.getValue();
    const dto = { email: currentEmail };
    setState({ isLoading: true });
    delayedApiCall(() => {
      dispatch(mfaOptionsForLogin(dto)).then(
        (result) => {
          if (!result) return;
          if (result.error) {
            err('ctaMfaOptions-ERROR', result.error);
            setState({ isLoading: false, extEmailError: 'Backend is unavailable at the moment. Please try again later.' });
          } else if (result.authOptions) {
            log('ctaMfaOptions - OK', result);
            const canShowSmsTab = result.authOptions.includes(AUTH_TYPE.SMS);
            const canShowEmailTab = result.authOptions.includes(AUTH_TYPE.EMAIL);
            const canShowAuthenticatorTab = result.authOptions.includes(AUTH_TYPE.AUTHENTICATOR);
            const activeTab = canShowEmailTab ? 'mfa-email' : canShowAuthenticatorTab ? 'mfa-authenticator' : canShowSmsTab ? 'mfa-sms' : null;
            setState({
              formState: FORM_STATE.PASSWORD,
              isLoading: false,
              needRecaptcha: result.needRecaptcha,
              needMfa: result.authOptions.length > 0,
              canShowSmsTab,
              canShowEmailTab,
              canShowAuthenticatorTab,
              activeTab,
              extEmailError: '',
              extPasswordError: '',
              extAccessCodeError: '',
              extAuthenticatorCodeError: '',
            });
          } else {
            setState({ isLoading: false, extEmailError: 'Something went wrong.' });
          }
        },
        (e) => {
          setState({ isLoading: false, errors: { email: 'Unknown error.' } });
          err('2FA-ERROR', e);
        },
      );
    });
  };

  const authTypeFromActiveTab = () => {
    switch (activeTab) {
      case 'mfa-email':
        return AUTH_TYPE.EMAIL;
      case 'mfa-sms':
        return AUTH_TYPE.SMS;
      case 'mfa-authenticator':
        return AUTH_TYPE.AUTHENTICATOR;
      default:
        return null;
    }
  };
  const generateDtoForRequestCodeVia = () => {
    const email = emailRef.current;
    const password = passwordRef.current;
    const result = { email: email.getValue(), password: password.getValue(), authType: authTypeFromActiveTab() };
    if (!email || !email.isValid() || !password || !password.isValid()) {
      warn('ctaMfaRequestCodeVia - MISSING PARAMS', result);
      return null;
    }
    return result;
  };

  const [accessCodeCounter, setAccessCodeCounter] = useState(0);
  useInterval(() => {
    if (formState === FORM_STATE.ACCESS_CODE && accessCodeCounter === 0) {
      warn('Access code has expired');
      setState({ formState: FORM_STATE.EDIT });
      return;
    }
    if (formState !== FORM_STATE.ACCESS_CODE || accessCodeCounter <= 0) {
      return;
    }
    // log('accessCodeCounter', accessCodeCounter);
    setAccessCodeCounter((currentCount) => currentCount - 1);
  }, 1000);

  const ctaMfaRequestCodeVia = () => {
    const dto = generateDtoForRequestCodeVia();
    if (!dto) {
      return;
    }
    setState({ isLoading: true, authType: dto.authType });
    delayedApiCall(() => {
      log('ctaMfaRequestCodeVia', dto);
      dispatch(mfaAccessCodeForLogin(dto)).then(
        (result) => {
          if (result) {
            setState({
              formState: FORM_STATE.ACCESS_CODE,
              isLoading: false,
              extEmailError: '',
              extPasswordError: '',
              extAccessCodeError: '',
              extAuthenticatorCodeError: '',
            });
            setAccessCodeCounter(result.expireInSeconds);
            // setAccessCodeCounter(10);
          } else {
            setState({ isLoading: false, extPasswordError: 'Access Code Error' });
          }
        },
        (e) => {
          setState({ isLoading: false });
          err('error', e);
        },
      );
    });
  };

  const generateDtoForPasswordChange = (captchaValue) => {
    const email = emailRef.current;
    const password = passwordRef.current;
    const newPassword = newPasswordRef.current;
    const repeatNewPassword = repeatNewPasswordRef.current;
    const accessCode = accessCodeRef.current;
    const authenticatorCode = authenticatorCodeRef.current;
    const authType = authTypeFromActiveTab();
    const result = {
      email: email.getValue(),
      password: password.getValue(),
      newPassword: newPassword.getValue(),
      authType: authType,
      reCaptchaValue: captchaValue,
    };
    const isValidEmail = email && email.isValid();
    const isValidPassword = password && password.isValid();
    const isValidNewPassword = newPassword && newPassword.isValid();
    const isValidRepeatNewPassword = repeatNewPassword && repeatNewPassword.isValid();
    if (newPassword.getValue() !== repeatNewPassword.getValue()) {
      warn('generateDtoForPasswordChange PASSWORDS MUST MATCH', newPassword.getValue(), repeatNewPassword.getValue());
      setState({ extRepeatNewPasswordError: 'Passwords must match.' });
      return null;
    } else {
      setState({ extRepeatNewPasswordError: '' });
    }
    if (needMfa) {
      if (authType === AUTH_TYPE.AUTHENTICATOR) {
        const isValidAuthenticatorCode = authenticatorCode && authenticatorCode.isValid();
        if (!isValidEmail || !isValidPassword || !isValidNewPassword || !isValidRepeatNewPassword || !isValidAuthenticatorCode) {
          warn('generateDtoForPasswordChange (AuthenticatorCode) - MISSING PARAM', result);
          return null;
        }
        result['authenticatorCode'] = authenticatorCode.getValue();
      } else if (authType === AUTH_TYPE.EMAIL || authType === AUTH_TYPE.SMS) {
        const isValidAccessCode = accessCode && accessCode.isValid();
        if (!isValidEmail || !isValidPassword || !isValidNewPassword || !isValidRepeatNewPassword || !isValidAccessCode) {
          warn('generateDtoForPasswordChange (AccessCode) - MISSING PARAM', result);
          return null;
        }
        result['accessCode'] = accessCode.getValue();
      }
    } else {
      if (!isValidEmail || !isValidPassword || !isValidNewPassword || !isValidRepeatNewPassword) {
        warn('generateDtoForPasswordChange - MISSING PARAM', result);
        return null;
      }
    }
    return result;
  };

  const ctaPasswordChange = (captchaValue) => {
    const dto = generateDtoForPasswordChange(captchaValue);
    if (!dto) {
      return;
    }
    setState({ isLoading: true });
    delayedApiCall(() => {
      log('ctaPasswordChange', dto);
      dispatch(mfaPasswordChange(dto)).then(
        (result) => {
          log('passwordChange', result);
          // TODO
          if (result && result.error) {
            let errorMessage;
            if (result.statusCode === 204) {
              errorMessage = 'This user is not existing in the system';
            } else {
              errorMessage = result.error.response.data;
            }
            if (needMfa) {
              setState({ isLoading: false, extAccessCodeError: errorMessage });
            } else {
              setState({ isLoading: false, extPasswordError: errorMessage });
            }
          } else if (result) {
            setState({ formState: FORM_STATE.ACCESS_CODE, isLoading: false });
          } else {
            setState({ formState: FORM_STATE.SUCCESS, isLoading: false });
          }
        },
        (e) => {
          setState({ isLoading: false });
          err('error', e);
        },
      );
    });
  };

  const ctaPasswordChangeCaptcha = () => {
    log('ctaPasswordChangeCaptcha', state, needRecaptcha, recaptchaRef);
    if (needRecaptcha && recaptchaRef) {
      const captcha = recaptchaRef.current;
      if (!generateDtoForPasswordChange()) {
        warn('Invalid form before captcha');
        return;
      }
      if (!captcha || !captcha.isReady()) {
        warn('Captcha is not ready');
        return;
      }
      captcha.callback((captchaValue) => {
        ctaPasswordChange(captchaValue);
      });
    } else {
      ctaPasswordChange();
    }
  };

  const ctaPasswordChangeAuthenticator = (captchaValue) => {
    log('ctaPasswordChangeAuthenticator', state);
    const dto = generateDtoForPasswordChange(captchaValue);
    if (!dto) {
      return;
    }
    setState({ isLoading: true });
    delayedApiCall(() => {
      log('ctaPasswordChangeAuthenticator', dto);
      dispatch(mfaPasswordChange(dto)).then(
        (result) => {
          log('passwordChangeAuthenticator', result);
          if (result && result.error) {
            let errorMessage;
            // TODO
            if (result.statusCode === 204) {
              errorMessage = 'This user is not existing in the system';
            } else {
              errorMessage = 'Invalid or expired password or authenticator code.';
            }
            setState({ isLoading: false, extAuthenticatorCodeError: errorMessage });
          } else if (result) {
            setState({ formState: FORM_STATE.ACCESS_CODE, isLoading: false });
          } else {
            setState({ formState: FORM_STATE.SUCCESS, isLoading: false });
          }
        },
        (e) => {
          setState({ isLoading: false });
          err('error', e);
        },
      );
    });
  };

  const ctaPasswordChangeCaptchaAuthenticator = () => {
    log('ctaPasswordChangeCaptchaAuthenticator', state, needRecaptcha, recaptchaRef);
    if (needRecaptcha && recaptchaRef) {
      const captcha = recaptchaRef.current;
      if (!generateDtoForPasswordChange()) {
        warn('Invalid form before captcha');
        return;
      }
      if (!captcha || !captcha.isReady()) {
        warn('Captcha is not ready');
        return;
      }
      captcha.callback((captchaValue) => {
        ctaPasswordChangeAuthenticator(captchaValue);
      });
    } else {
      ctaPasswordChangeAuthenticator();
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();
    // log('onSubmit', event, activeTab, formState);
  };

  const onTabSelection = (e) => {
    // log('onTabSelection', e);
    setState({ activeTab: e });
  };

  const {
    formState,
    activeTab,
    isLoading,
    needRecaptcha,
    needMfa,
    canShowEmailTab,
    canShowAuthenticatorTab,
    canShowSmsTab,
    extEmailError,
    extNewPasswordError,
    extRepeatNewPasswordError,
    extAuthenticatorCodeError,
    extAccessCodeError,
    extPasswordError,
  } = state;

  const notInEditState = formState !== FORM_STATE.EDIT;

  return (
    <TWAuthLayout title={'Expired password'} comment={formState !== FORM_STATE.SUCCESS ? 'Your password has expired and must be changed.' : ''}>
      <form className="space-y-2" action="#" method="POST" noValidate={true} onSubmit={onSubmit}>
        {/*email*/}
        <TWFormElementEmail
          id={'2fa-email'}
          ref={emailRef}
          show={formState !== FORM_STATE.SUCCESS}
          disabled={isLoading || notInEditState}
          showEditButton={notInEditState}
          onEditClick={() => {
            setState({ formState: FORM_STATE.EDIT });
          }}
          extError={extEmailError}
        />
        {/*continue button*/}
        <Button show={formState === FORM_STATE.EDIT} isLoading={isLoading} cta={ctaMfaOptions}>
          Continue
        </Button>
        {/*password*/}
        <TWFormElementPassword
          show={formState === FORM_STATE.PASSWORD || formState === FORM_STATE.ACCESS_CODE}
          id={'2fa-password'}
          ref={passwordRef}
          title={'Current password'}
          placeholder={'Your current password'}
          autoFocus={true}
          showForgotPassword={true}
          disabled={isLoading || formState !== FORM_STATE.PASSWORD}
          extError={extPasswordError}
        />
        {/*new-password*/}
        <PasswordHint show={formState === FORM_STATE.PASSWORD || formState === FORM_STATE.ACCESS_CODE} />
        <TWFormElementPassword
          show={formState === FORM_STATE.PASSWORD || formState === FORM_STATE.ACCESS_CODE}
          id={'2fa-new-password'}
          name={'newPassword'}
          ref={newPasswordRef}
          disabled={isLoading}
          title={'New password'}
          placeholder={'Your new password'}
          emptyErrorMessage={'Please enter your new password.'}
          invalidErrorMessage={'Please enter a valid new password.'}
          extError={extNewPasswordError}
        />
        {/*repeat-password*/}
        <TWFormElementPassword
          show={formState === FORM_STATE.PASSWORD || formState === FORM_STATE.ACCESS_CODE}
          id={'2fa-repeat-new-password'}
          name={'repeatNewPassword'}
          ref={repeatNewPasswordRef}
          disabled={isLoading}
          title={'Repeat new password'}
          placeholder={'Your new password again'}
          emptyErrorMessage={'Please enter your new password again.'}
          invalidErrorMessage={'Please enter a valid new password again.'}
          extError={extRepeatNewPasswordError}
        />
        {/*tab navigation*/}
        {needMfa && formState === FORM_STATE.PASSWORD && (
          <>
            <p className={'font-medium'}>Please select a multi-factor authentication (MFA)</p>
            <TabNav defaultActiveTab={activeTab ? activeTab : 'mfa-email'} onTabSelection={onTabSelection}>
              {/*Email*/}
              <TabNavItem show={canShowEmailTab} title={'Email'} tabKey={'mfa-email'}>
                {formState === FORM_STATE.PASSWORD && (
                  <>
                    <p className={'mb-4 text-sm'}>Hint: You can request an access code via Email, which you can use to change your password.</p>
                    <Button isLoading={isLoading} cta={ctaMfaRequestCodeVia} show={!activeTab || activeTab === 'mfa-email'}>
                      Request code via Email
                    </Button>
                  </>
                )}
              </TabNavItem>
              {/*Google Authenticator*/}
              <TabNavItem show={canShowAuthenticatorTab} title={'Authenticator'} tabKey={'mfa-authenticator'}>
                <>
                  <TWFormElementAuthenticatorCode
                    show={true}
                    id={'2fa-authenticator-code'}
                    ref={authenticatorCodeRef}
                    disabled={isLoading}
                    extError={extAuthenticatorCodeError}
                    toShowTrustedDeviceCheckBox={false}
                  />
                  <Button isLoading={isLoading} cta={ctaPasswordChangeCaptchaAuthenticator} show={activeTab === 'mfa-authenticator'}>
                    Change password
                  </Button>
                </>
              </TabNavItem>
              {/*SMS*/}
              <TabNavItem show={canShowSmsTab} title={'SMS'} tabKey={'mfa-sms'}>
                <>
                  <p className={'mb-4 text-sm'}>Hint: You can request an access code via SMS, which you can use to change your password.</p>
                  <Button isLoading={isLoading} cta={ctaMfaRequestCodeVia} show={activeTab === 'mfa-sms'}>
                    Request code via SMS
                  </Button>
                </>
              </TabNavItem>
            </TabNav>
          </>
        )}
        {/*access code*/}
        {needMfa && formState === FORM_STATE.ACCESS_CODE && (
          <>
            <TWFormElementAccessCode
              show={true}
              id={'2fa-access-code'}
              ref={accessCodeRef}
              disabled={isLoading || formState !== FORM_STATE.ACCESS_CODE}
              accessCodeExpirationCounter={accessCodeCounter}
              extError={extAccessCodeError}
              toShowTrustedDeviceCheckBox={false}
            />
            <p className={'mb-4 text-sm'}>Hint: Please enter the access code you received by e-mail.</p>
            <Button isLoading={isLoading} cta={ctaPasswordChangeCaptcha} show={true}>
              Change password
            </Button>
          </>
        )}
        {/*change password*/}
        <Button show={!needMfa && formState === FORM_STATE.PASSWORD} isLoading={isLoading} cta={ctaPasswordChangeCaptcha}>
          Change password
        </Button>
        {/*recaptcha*/}
        <ReCaptcha enabled={needRecaptcha} ref={recaptchaRef} />
        {/*success page*/}
        {formState === FORM_STATE.SUCCESS && (
          <>
            <p className={'font-medium mb-4'}>If your original password was correct, your password has been changed successfully.</p>
            <GoToLogin />
          </>
        )}
      </form>
    </TWAuthLayout>
  );
};
