import React, {useState} from 'react'
import { useDispatch } from 'react-redux';
import { clearCaseMessages } from 'redux/actions/cases/caseActions';

const boxClass = {
    'success': 'alert-success',
    'error': 'alert-danger',
    'warning': 'alert-warning'
};

const ValidMessage = ({validatedField, goToTab}) => {
    const {id, field, message, key, tab} = validatedField;

    const onClick = () => {
        if (goToTab && tab) {
            // console.log(tab);
            goToTab(tab);
            console.log(id);
            setTimeout(() => {
                const element = document.getElementById(id);
                if (element) element.focus()
            }, 500);
        }
    };

    return (
        <div className='row' key={key} style={{maxWidth: '700px'}}>
            <div className='col-md-4'>
                <label htmlFor={id} style={{textDecoration: 'underline', cursor: 'pointer'}}
                       onClick={onClick}>{field}</label>
            </div>
            <div className='col-md-8'>
                <span>{message}</span>
            </div>
        </div>
    )
};

const ValidationBlock = ({validationMessages, goToTab}) => {
    if (!validationMessages) return '';

    return validationMessages
        .filter((item) => item.message)
        .map((item, index) => <ValidMessage validatedField={item} goToTab={goToTab} key={index}/>)
};


const SystemMessage = ({top, type, shown, message, validationMessages, goToTab, style, preventAddClasses = false, time}) => {
    if (!time) {
      time = 500
    }
    const [timer, setTimer] = useState(false);
    const dispatch = useDispatch();
    let display = 'none';

    if (type === 'error') {
        display = message || (shown && validationMessages && validationMessages.filter((item) => item.message).length) ? 'block' : 'none';
    } else if (type === 'success' || type === 'warning') {
        display = shown ? 'block' : 'none';
    }

    if (display === 'block' && !timer && time && message && message != '') {
        setTimer(true);
        setTimeout(() => {
            dispatch(clearCaseMessages());
            setTimer(false);
        }, parseInt(time, 10) > 3000 ? parseInt(time, 10) : 3000);
    }

    if (!preventAddClasses && shown && validationMessages) {
            validationMessages.forEach((item) => {
            let element = document.getElementById(item.id);

            if (!element) return;

            if (element.parentNode['classList'].contains('Select-input')) {
                element = element.parentNode.parentNode.parentNode
            }

            if (element.classList.contains('rw-datetime-picker')) {
                element.id = '';
                element = element.firstChild;
                element.firstChild.id = item.id;
            }

            if (element.classList.contains('rw-widget-input')) {
                element = element.parentNode
            }

            item.message ? element.classList.add('invalid_form_field') : element.classList.remove("invalid_form_field")
        })
    }
    return (
        <div className={`alert ${boxClass[type]} system_message_box`} style={{display, top, ...style}}>
            {typeof message === 'string' ? message : 'An unexpected error occurred.'}
            {shown && <ValidationBlock validationMessages={validationMessages} goToTab={goToTab}/>}
        </div>
    )
};

export default SystemMessage;
