import React from 'react';
import connect from "react-redux/es/connect/connect";
import {isClient} from "../../../utils/helpers";
import Modal from "react-modal";
import {closeCreateModal, openCreateModal} from "../../../redux/action-creators/users";
import {getOne} from "../../../redux/actions/BasicEntityActions";
import PropTypes from "prop-types";
import shortId from "shortid";
import {createNewCase} from '../../../redux/actions/cases/caseListActions'
import {setCaseIsCreated} from '../../../redux/actions/cases/caseActions'
import SimpleReactValidator from "utils/simple-react-validator";
import CreateCaseForm from "../CreateCaseForm";
import {withRouter} from "react-router-dom";
import { fetchCaseCount } from 'redux/action-creators/init';

class CaseCreate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            company: '',
            loading: false
        };

        this.validator = new SimpleReactValidator();

        this.createCase = this.createCase.bind(this);
        this.createCaseMyCompany = this.createCaseMyCompany.bind(this);
    }

    componentDidMount() {
        Modal.setAppElement('body');

        if (isClient(this.props.currentUser.roleCode)) {
            let company = {
                value: this.props.currentUser.companyCode
            };
            this.setState({company: company});
        }
    }

    onChangeCompany(company) {
        this.setState({company})
    }

    saveCase() {
        // this.props.companies.forEach((item) => {
        //     if (this.state.company.value === item.code) {
        //         templateCode = item.defaultTemplateCode;
        //
        //     }
        // });

        this.setState({ loading: true });

        this.props.getOne(this.state.company.value)
            .then((value) => {
                if (!value) {
                    this.props.modalClose();
                    this.props.history.push(`/home`);
                    return
                }

                let caseCode = shortId.generate();
                this.props.createNewCase({
                    code: caseCode,
                    company: this.state.company.value,
                    patientCaseType: "MN",
                    ownerDraft: this.props.currentUser.code,
                    templateCode: value.defaultTemplateCode,
                    level: 'INITIAL',
                    language: value.defaultLanguageCode
                }).then(
                    () => {
                        this.props.setCaseIsCreated(true);
                        this.props.modalClose();
                        this.props.history.push(`/case/` + caseCode);
                        this.props.fetchCaseCount();
                    },
                    () => {
                        this.props.modalClose();
                        this.props.history.push(`/home`)
                    },
                ).finally(() => {
                  this.setState({ loading: false });
              });
            })

    }

    createCaseMyCompany() {
        this.saveCase();
    }

    createCase(e) {
        e.preventDefault();
        if (this.validator.allValid()) {
            this.saveCase();
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    render() {
        //
        const visibility = this.props.isVisible ? 'visible' : 'hidden';
        return (
            <span style={{visibility}}>
                <button disabled={this.state.loading}
                        className="btn resizable-button tooltip-button btn-success margin-left-5"
                        onClick={isClient(this.props.currentUser.roleCode) ? this.createCaseMyCompany : this.props.modalOpen}>
                    <i className={`fa fa-list-alt`}/> {this.context.translate('common.cases.create')}
                </button>
                {
                    !isClient(this.props.currentUser.roleCode) ?
                        <Modal
                            isOpen={this.props.isOpen}
                            contentLabel="onRequestClose Example"
                            onRequestClose={this.props.modalClose}
                            className="custom-modal modal-dialog"
                            overlayClassName="modal-overlay"
                        >
                            <h3 className="modal-title text-center"> {this.context.translate('common.form.add_case')}
                                <i className='fa fa-times close' onClick={this.props.modalClose}> </i>
                            </h3>
                            <div className='white-bg'>
                                <CreateCaseForm> </CreateCaseForm>
                            </div>
                        </Modal>
                        :
                        ''
                }
            </span>
        )
    }
}

CaseCreate.contextTypes = {
    translate: PropTypes.func
};

const mapState = (state) => {
    const my_permissions = state.app.users.my_permissions;
    return {
        isVisible: my_permissions.includes('CREATE_CASES'),
        currentUser: state.app.auth.user,
        lastVersion: state.app.template,
        isOpen: state.app.users.createModalOpen,
        companies: state.app.companies.data,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        modalOpen: () => dispatch(openCreateModal()),
        modalClose: () => dispatch(closeCreateModal()),
        getOne: (data) => dispatch(getOne(data, 'company')),
        setCaseIsCreated: (data) => dispatch(setCaseIsCreated(data)),
        createNewCase: (data) => dispatch(createNewCase(data)),
        fetchCaseCount: () => dispatch(fetchCaseCount()),
    }
};

export default connect(mapState, mapDispatchToProps)(withRouter(CaseCreate));
