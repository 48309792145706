// #region screen imports
import Profile from './pages/Profile/ProfilePage';
import UsersPage from './pages/Users';
import ContactUs from './pages/ContactUs';

import StaffManagementPage from './pages/Staff/StaffManagementPage';
import StaffEditPage from './pages/Staff/StaffEditPage';
import PrivilegesManagePage from './pages/Admin/PrivilegesManagePage';
import SettingsManagePage from './pages/Admin/SettingsManagePage';
import ReviewerEditPage from './pages/Admin/ReviewerEditPage';

import TemplateManagePage from './pages/Template/TemplateManagePage';
import TemplatesList from './pages/Template/TemplatesList';
import CreateFieldInstance from './pages/Template/Fields/CreateFieldInstance';
import CloneFieldInstance from './pages/Template/Fields/CloneFieldInstance';
import EditFieldInstance from './pages/Template/Fields/EditFieldInstance';
import FieldsList from './pages/Entities/Fields/FieldsList';
import CreateField from './pages/Entities/Fields/CreateField';
import EditField from './pages/Entities/Fields/EditField';

import BillingGroupList from './pages/BillingGroups/BillingGroupList';
import ManageBillingGroup from './pages/BillingGroups/ManageBillingGroup';
import CreateNewTier from './pages/BillingGroups/Tiers/CreateNewTier';
import UpdateTier from './pages/BillingGroups/Tiers/UpdateTier';

import ClientEditPage from './pages/Company/ClientEditPage';
import ClientMedicalCodingSystem from './pages/Company/ClientMedicalCodingSystem';
import CompanyEditPage from './pages/Company/CompanyEditPage';

import MCSystem from './pages/MedicalServices/MCSystemLists';
import CodingSystemEdit from './pages/MedicalServices/CodingSystems/CodingSystemEditPage';
import CodeEdit from './pages/MedicalServices/Codes/CodeEditPage';
import ProcedureEdit from './pages/MedicalServices/Procedures/ProcedureEditPage';
import DiagnoseEdit from './pages/MedicalServices/Diagnoses/DiagnoseEditPage';

import PatientList from './pages/Patients/PatientList';
import PatientEditPage from './pages/Patients/PatientEditPage';

import UserManagePage from './pages/ClientUsers/UserManagePage';
import UserEditPage from './pages/ClientUsers/UserEditPage';

import SpecialtiesList from './pages/BasicEntities/Specialties/SpecialtiesList';
import SpecialtiesEditPage from './pages/BasicEntities/Specialties/SpecialtyEditPage';
import CountriesList from './pages/BasicEntities/Countries/CountriesList';
import CountryEditPage from './pages/BasicEntities/Countries/CountryEditPage';

import FacilitiesPage from './pages/Facility/FacilitiesPage';
import FacilityEditPage from './pages/Facility/FacilityEditPage';
import FacilityTypeEditPage from './pages/Facility/FacilityTypeEditPage';
import PhysiciansManage from './pages/Physician/PhysiciansManage';
import PhysicianCreateForm from './pages/Physician/PhysicianCreateForm';
import PhysicianEditPage from './pages/Physician/PhysicianEditPage';
import PhysicianPage from "./pages/Physician/PhysiciansPage";

import CaseHome from './pages/Case/Home';
import Home from 'pages/Home';
import CaseList from './pages/Case/List';
import Case from './pages/Case/Case';
import Details from './pages/Case/Details/Details';
import AssignReviewersPage from './pages/Case/Assignments/AssignReviewersPage';
import FinancialReportForm from './pages/FinancialReport/FinancialReportForm';
import CaseTypesList from './pages/Entities/CaseTypes/CaseTypesList';
import CaseTypeEditPage from './pages/Entities/CaseTypes/CaseTypeEditPage';
import MenuItemsList from './pages/MenuManager/MenuItemsList';

import NotificationTemplateEditPage from './pages/NotificationTemplate/NotificationTemplateEditPage';
import NotificationTemplatePage from 'pages/NotificationTemplate/NotificationTemplatePage';
import DevelopRandomPopulatePage from 'pages/Develop/DevelopRandomPopulatePage';
import PatientsPage from './pages/Patients/PatientsPage';
import SelectDemo from 'pages/Develop/SelectDemo';
import CaseFileTemplatePage from './pages/CaseFileTemplate/CaseFileTemplatePage';
import CaseFileTemplateEditPage from './pages/CaseFileTemplate/CaseFileTemplateEditPage';
import QueuedMessagesPage from './pages/QueuedMessages/QueuedMessagesPage';
import ApiTester from './pages/Develop/ApiTester';
import DevelopTestPage from './pages/Develop/DevelopTestPage';
import ReviewersPage from "./pages/Admin/ReviewersPage";
import { Login2FAForm } from "./pages/Auth/Login2FA/Login2FAForm";
import { PasswordReset2FAForm } from "./pages/Auth/Login2FA/PasswordReset2FAForm";
import { PasswordRecovery2FAForm } from "./pages/Auth/Login2FA/PasswordRecovery2FAForm";
import { PasswordExpiration2FAForm } from "./pages/Auth/Login2FA/PasswordExpiration2FAForm";
import { FirstLogin2FAForm } from "./pages/Auth/Login2FA/FirstLogin2FAForm";
// #endregion

const NEW_AUTH_ROUTES = [
    { path: '/login', component: Login2FAForm },
    { path: '/login/password-reset', component: PasswordReset2FAForm },
    { path: '/login/password-expiration', component: PasswordExpiration2FAForm },
    { path: '/link/password-recovery/:key', component: PasswordRecovery2FAForm },
    { path: '/link/first-login/:key', component: FirstLogin2FAForm },
]

const MAIN_ROUTES = [
    // Home
    { path: '/', component: CaseHome },
    { path: '/home', component: Home },

    // User
    { path: '/user/', component: UsersPage }, //?
    { path: '/help_desk/', component: ContactUs },
    { path: '/profile/', component: Profile },

    // Cases
    { path: '/cases', component: Case }, // TODO: deprecated
    { path: '/case/:id', component: Details },
    { path: '/all_cases', component: CaseList },
    { path: '/draft', component: CaseList },
    { path: '/dispatch', component: CaseList },
    { path: '/review', component: CaseList },
    { path: '/qa', component: CaseList },
    { path: '/flagged', component: CaseList },
    { path: '/closed', component: CaseList },
    { path: '/available', component: CaseList },
    { path: '/accepted', component: CaseList },
    { path: '/in_process', component: CaseList },
    { path: '/in_progress', component: CaseList },
    { path: '/complete', component: CaseList },
    { path: '/closed_cases', component: CaseList },
    { path: '/closed_cases_this_month', component: CaseList },
    { path: '/closed_cases_last_month', component: CaseList },

    { path: '/assign/:id', component: AssignReviewersPage },

    // Patients
    { path: '/patients', component: PatientsPage },
    { path: '/patients_old', component: PatientList }, // TODO: deprecated
    { path: '/patients/profile/:id', component: PatientEditPage },

    // Search TODO: not implemented yet v1.0.0?

    // Templates
    { path: '/templates', component: TemplatesList },
    { path: '/templates/update/:id', component: TemplateManagePage },
    { path: '/templates/field_instances', component: FieldsList },
    { path: '/templates/field_instances/create', component: CreateFieldInstance },
    { path: '/templates/field_instances/clone', component: CloneFieldInstance },
    { path: '/templates/field_instances/update/:id', component: EditFieldInstance },

    { path: '/fields', component: FieldsList },
    { path: '/fields/create', component: CreateField },
    { path: '/fields/update/:id', component: EditField },

    // { path: '/case_file_templates', component: CaseFileTemplateList },
    { path: '/case_file_templates', component: CaseFileTemplatePage },
    { path: '/case_file_templates/update/:id', component: CaseFileTemplateEditPage },

    // Clients
    { path: '/clients', component: CompanyEditPage },
    { path: '/clients/profile/:id', component: ClientEditPage },

    { path: '/submitters', component: UserManagePage },
    { path: '/submitters/profile/:id', component: UserEditPage },

    { path: '/medical_coding_system', component: ClientMedicalCodingSystem },

    // Reviewers
    // { path: '/reviewers', component: ReviewersManagePage },
    { path: '/reviewers', component: ReviewersPage },
    { path: '/reviewers/profile/:id', component: ReviewerEditPage },

    // Staff
    { path: '/staff', component: StaffManagementPage },
    { path: '/staff/profile/:id', component: StaffEditPage },

    // Tools
    { path: '/notification_templates', component: NotificationTemplatePage },
    { path: '/notification_templates/update/:id', component: NotificationTemplateEditPage },

    // TODO: Translations page? v1.0.0?

    { path: '/permissions', component: PrivilegesManagePage },

    // Tables
    { path: '/physicians', component: PhysicianPage},
    { path: '/physicians_old', component: PhysiciansManage },
    { path: '/physicians/create', component: PhysicianCreateForm },
    { path: '/physicians/:id', component: PhysicianEditPage },

    { path: '/specialties', component: SpecialtiesList },
    { path: '/specialties/:id', component: SpecialtiesEditPage },

    { path: '/facilities', component: FacilitiesPage },
    { path: '/facilities/:type', component: FacilitiesPage },
    { path: '/facility/:id', component: FacilityEditPage },
    { path: '/facility_type/:id', component: FacilityTypeEditPage },

    { path: '/medical_services', component: MCSystem },
    { path: '/medical_services/:type', component: MCSystem },
    { path: '/procedure/:id', component: ProcedureEdit },
    { path: '/diagnose/:id', component: DiagnoseEdit },

    { path: '/coding_system/:id', component: CodingSystemEdit },
    { path: '/coding_system_code/:id', component: CodeEdit },

    { path: '/case_types', component: CaseTypesList },
    { path: '/case_types/update/:id', component: CaseTypeEditPage },

    { path: '/countries', component: CountriesList },
    { path: '/countries/:id', component: CountryEditPage },

    // Finances
    { path: '/billing_groups', component: BillingGroupList },
    { path: '/billing_groups/update/:id', component: ManageBillingGroup },

    { path: '/billing_tier/create/tier/:id', component: CreateNewTier },
    { path: '/billing_tier/update/tier/:id', component: UpdateTier },

    { path: '/financial_reports', component: FinancialReportForm },
    { path: '/case_reports', component: FinancialReportForm },

    // Notifications
    { path: '/notifications', component: QueuedMessagesPage },

    // TODO:
    { path: '/general_settings', component: SettingsManagePage },
    { path: '/menu_manager', component: MenuItemsList },
];

// TODO: we should use these pages in different way, DO NOT USE them in the final production code
const DEV_ROUTES = [
    { path: '/dev_random_populate', component: DevelopRandomPopulatePage },
    { path: '/dev_api_tester', component: ApiTester },
    { path: '/dev_test', component: DevelopTestPage },
];

const DEV_ROUTES_WITHOUT_LAYOUT = [
    { path: '/select_demo', component: SelectDemo },
];

export { NEW_AUTH_ROUTES, MAIN_ROUTES, DEV_ROUTES, DEV_ROUTES_WITHOUT_LAYOUT };
