import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';

function RemoveButton(props) {
  
    const [open, setOpen] = useState(false);
    const firstDispatchDate = useSelector((state) => state.app.cases.editedCase.firstDispatchDate);

    const handleClose = () => setOpen(false);
    const onClick = () => {
        const { needConfirmation } = props;
        if (needConfirmation) {
            setOpen(true);
        } else {
            props.onClick()
                .then(handleClose)
                .catch(handleClose);
        }
    };

    const { isVisible, style, className, icon, title, content, isLocked, disabled} = props;
    const display = isVisible ? 'inline-block' : 'none';

    return (
        <span>
            <button className={`btn resizable-button tooltip-button ${className}`}
                    style={{ ...style, display }}
                    title={title}
                    onClick={onClick}
                    disabled={disabled || firstDispatchDate !== null || isLocked}
            >
                <i className={`fa ${icon}`} />
                {content && <span style={{ marginLeft: '5px' }}>{content}</span>}
            </button>

            <Modal
                isOpen={open}
                onRequestClose={handleClose}
                className="custom-modal modal-dialog"
                overlayClassName="modal-overlay"
            >
                <form className='modal-content custom-modal-content'>
                    <h3 className="text-center">Do you really want remove this case?</h3>
                    <div className="text-center">
                        <button
                            className='btn btn-grey margin-left-5 margin-right-5 margin-top-5'
                            onClick={(e) => {
                                e.preventDefault();

                                props.onClick()
                                    .then(handleClose)
                                    .catch(handleClose);
                            }}>
                            Yes
                        </button>
                        <button
                            className='btn btn-grey margin-left-5 margin-top-5'
                            onClick={handleClose}>
                            No
                        </button>
                    </div>
                </form>
            </Modal>
        </span>
    );
}

RemoveButton.propTypes = {
    needConfirmation: PropTypes.bool,
    onClick: PropTypes.func,
    isVisible: PropTypes.bool,
    style: PropTypes.object,
    className: PropTypes.string,
    icon: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.string,
    disabled: PropTypes.bool,
};

export default RemoveButton;
